$white: #fff;

$base-color: #3b3b3b;
$gray-text: #9d9d9d;
$dark-gray: #444;
$light-gray: #e5e5e5;
$green-text: #0e7d7d;
$light-green: #00d06c;
$green-border: #009688;
$dark-green: #0a6161;
$red-text: #ff5b5b;
$text-blue: #3f88c5;
$gray-bg: #f7f7f7;
$deep-sea-green: #085a5a;
$pine-green: #158b8b;
$rose-white: #eaeffb;
$picton-blue: #5ca4da;
$steel-blue: #457ba3;
$scandal-color: #b7d8d8;
$fountain-blue: #68b9b9;
$glacier: #87bebe;

@mixin truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin transition($transition-value, $transition-time) {
  -webkit-transition: $transition-value $transition-time ease-in-out;
  -moz-transition: $transition-value $transition-time ease-in-out;
  -o-transition: $transition-value $transition-time ease-in-out;
  transition: $transition-value $transition-time ease-in-out;
}
.client__order {
  height: calc(100% - 64px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  &-main {
    height: calc(100vh - 100px);
    overflow-y: auto;
    & .client__order .step-6 {
      overflow-y: hidden;
      height: 100%;
      margin-top: 0px;
      .order__confirm {
        height: 100%;
        min-height: unset;
        overflow-y: auto;
        .order__details {
          margin-top: 0px;
          width: 250px;
          .back__order {
            margin-top: 10px;
            &.list {
              a {
                color: #0e7d7d;
                background: #fff;
                border: 1px solid #0e7d7d;
              }
            }
          }
          a {
            text-transform: uppercase;
          }
          .order__email--wrapper {
            margin-top: 0px;
          }
        }
      }
    }
  }
  &-footer {
    height: 64px;
    width: 100%;
    display: flex;
    .summary__total--button {
      display: flex;
      justify-content: center;
      align-items: center;
      & > .summary__back--button {
        width: 100%;
        & > a.summary__back {
          width: 100%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          min-height: 40px;
          background: #ebf1f1;
          border-radius: 3px;
          font-weight: 600;
          transition: 0.4s ease all;
          &:hover {
            opacity: 0.7;
          }
          color: #0e7d7d;
          & span {
            color: #0e7d7d !important;
          }
        }
      }
    }
  }
  .button:not(.button-close) {
    cursor: pointer;
    font-weight: 500;
    left: 3px;
    line-height: inherit;
    position: relative;
    text-decoration: none;
    text-align: center;
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    display: inline-block;
  }

  .button--small {
    padding: 10px 20px;
    font-size: 0.875rem;
  }

  .button--green {
    outline: none;
    background-color: #64d18a;
    border-color: #64d18a;
    color: white;
    transition: all 200ms ease;
  }

  .button--green:hover {
    background-color: #8bdda8;
    color: white;
  }
  .select__dropdown-scroll {
    overflow: scroll;
  }
  .file-drop-dragging-over-frame .upload-content__drag-drop,
  .file-drop-dragging-over-frame .upload-content__files {
    border: 3px dashed #7ab8ec;
    background: #fbfdff;
  }
  .new__permissions {
    width: 100%;
  }
  .tag__search .search__dropdown {
    z-index: 5;
  }
  .wrap-btn-add {
    border: none;
  }
  .upload-content__drag-drop__old {
    padding: 40px 0 100px;
  }
  .upload-content__drag-drop svg {
    margin-left: auto;
    margin-right: auto;
  }
  .new__permissions input {
    background: #fff;
  }
  .upload-content__files {
    padding-top: 0;
  }
  .upload-content__files:after {
    content: none;
  }
}

@media (max-width: 767px) {
  .upload-content__files-list .form-group:hover .upload-edit {
    display: block;
  }
}
.upload-content__files-list__options .button {
  padding: 0;
}

.client__order-footer > .summary__total--button > button {
  display: inline-flex;
  width: 145px;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  background: #0e7d7d;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 10%);
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  transition: 0.4s ease all;
}
.search__select--field input {
  background: #fff;
}
@media (max-width: 768px) {
  .select__two .select__multi--field .multi__dropdown .multi__list {
    max-height: 200px;
  }
}
#payment-element iframe {
  min-height: 250px !important;
}
.paymentTesting #payment-element iframe {
  min-height: unset !important;
}
.select__once--field .select__main--dropdown .search__select--dropdown,
.select__two .select__multi--field .multi__dropdown .multi__list {
  max-height: 195px;
}
.client__order-footer {
  & .summary__total--button {
    width: 100%;
    justify-content: flex-end;
    align-items: center;

    & > .summary__back--button {
      max-width: 145px;
      margin-right: 16px;
      & > a.summary__back {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-height: 40px;
        border-radius: 8px;
        text-decoration: none;
        color: #0e7d7d !important;
        border: 1px solid #0e7d7d;
        transition: 0.3s ease all;
        &:hover {
          opacity: 0.7;
        }
      }
    }
    & > a {
      display: inline-flex;
      width: 145px;
      align-items: center;
      justify-content: center;
      min-height: 40px;

      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      color: #ffffff;
      background-color: #0e7d7d;
      text-decoration: none;
      transition: 0.4s ease all;
      &:active {
        background-color: #094f4f;
      }
      &:focus {
        color: #ffffff;
        background-color: #0a5858;

        box-shadow: 0 0 0 0.2rem rgba(14, 125, 125, 0.5);
      }
      &:hover {
        background-color: #0a5858;
      }
      & span {
        color: rgba(255, 255, 255);
      }
    }
  }
}

@media (max-width: 1024px) {
  .client__order-footer {
    & .summary__total--button {
      display: flex;
    }
  }
}

.payment__method--grid {
  .payment-type {
    .payment-type-select {
      display: flex;
      justify-content: space-between;
    }
  }
}

.profile__header {
  &.guest__profile {
    & .user-dropdown__toggle-name {
      color: #3b3b3b;
      font-size: 13px;
      line-height: 17px;
    }
  }
}

.complex__content {
  width: 100%;
  height: 100%;

  width: calc(100% - 50px);
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  height: auto;
  margin-top: 85px;
  min-height: calc(100vh - 110px);
  padding: 25px 30px;
  & .help__block {
    & > h2 {
      margin-top: 0px;
      margin-bottom: 20px;
      font-size: 25px;
      line-height: 34px;
      font-weight: bold;
      color: #0e7d7d;
    }
    & .help__links {
      & > .elem__help {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0px;
        }
        & > a {
          display: flex;

          align-items: flex-start;
          width: 100%;
          max-width: 540px;
          background: #ffffff;
          border: 1px solid #e3e3e3;
          border-radius: 10px;
          transition: 0.4s ease all;
          padding: 15px;
          padding-right: 75px;
          justify-content: flex-start;
          position: relative;
          &:hover {
            border-color: #0e7d7d;
            & .help__svg {
              background-color: #0e7d7d;
              & svg {
                & path {
                  fill: #fff;
                }
              }
            }
            & .help__arrow {
              & > img {
                opacity: 1;
              }
            }
          }
        }
        & .help__arrow {
          min-width: 46px;
          margin-left: 45px;
          position: absolute;
          right: 15px;
          top: 32px;
          & > img {
            opacity: 0.15;
            transition: 0.4s ease all;
          }
        }
        & .help__svg {
          min-width: 50px;
          max-width: 50px;
          min-height: 50px;
          margin-right: 10px;
          max-height: 50px;
          background: #e7f2f2;
          border-radius: 100px;
          display: inline-flex;
          transition: 0.4s ease all;
          align-items: center;
          & svg {
            & path {
              transition: 0.4s ease all;
            }
          }
          justify-content: center;
        }
        & .help__info {
          & > h6 {
            margin-top: 0px;
            margin-bottom: 3px;
            font-size: 16px;
            line-height: 21px;
            font-weight: bold;
            color: #0e7d7d;
          }
          & > p {
            margin: 0px;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: #3b3b3b;
          }
        }
      }
    }
  }
}

.client__order.checkout__long {
  width: 100%;
  margin-top: 0px;
  max-width: 1440px;
}
.checkout__progress--section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & .element__progress {
    display: flex;
    align-items: center;
    width: 100%;
    height: 45px;
    position: relative;
    justify-content: center;
    position: relative;

    &.done__progress {
      & > p {
        color: #0e7d7d;
      }
      & > span {
        background-color: #0e7d7d;
      }
    }
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    &.active__progress {
      background: #e7f2f2;
      & > p {
        color: #0e7d7d;
      }
      & span {
        border-color: #0e7d7d;
        background-color: #fff;
        color: #0e7d7d;
      }
    }
    & span {
      min-width: 30px;
      max-width: 30px;
      min-height: 30px;
      max-height: 30px;
      border-radius: 250px;
      background: #f5f5f5;
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      color: #2d2d2d;
      display: inline-flex;
      align-items: center;
      border: 2px solid #f5f5f5;
      justify-content: center;
      position: absolute;
      left: 18px;
    }
    clip-path: polygon(
      0 0,
      calc(100% - 15px) 0%,
      100% 50%,
      calc(100% - 15px) 100%,
      0 99%,
      15px 50%
    );
    background-color: #fff;
    & > p {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      margin: 0px;
      color: #2d2d2d;
    }
  }
}
.field__custom {
  width: 100%;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0px;
  }
  & > input {
    width: 100%;
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    background: #f5f5f5;
    border-radius: 3px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    text-decoration: none;
  }
  & > textarea {
    width: 100%;
    height: 70px;
    padding-left: 15px;
    padding-right: 15px;
    background: #f5f5f5;
    border-radius: 3px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    text-decoration: none;
    resize: none;
    padding-top: 10px;
  }
}
.checkout__long {
  //padding-bottom: 25px;
  & .content__guest {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 20px;
    & > .guest__content--main {
      & .lang__pair {
        & > .head__pair {
          margin-bottom: 30px;
          & > h2 {
            margin: 0px;
            font-size: 18px;
            line-height: 24px;
            font-weight: bold;
            color: #2d2d2d;
          }
        }
      }
      & .signed__form {
        & .element__signed--controls {
          max-width: 320px;
          margin-bottom: 30px;

          & .select__dropdown {
            margin-top: 0px;
          }
          &:last-child {
            margin-bottom: 0px;
          }
        }
        & .field__checkbox {
          & .checkbox__name {
            font-weight: 400;
          }
        }
        & form {
          & .signed__head {
            margin-bottom: 30px;
            & > p {
              margin: 0px;
              font-size: 14px;
              line-height: 20px;
              font-weight: 400;
              color: #2d2d2d;
              & a {
                font-weight: 600;
                color: #0e7d7d;
                text-decoration: underline;
              }
            }
            & > h2 {
              margin-top: 0px;
              margin-bottom: 10px;
              color: #2d2d2d;
              font-size: 18px;
              line-height: 24px;
              font-weight: bold;
              & a {
                color: #0e7d7d;
              }
            }
          }
        }
      }
      & .sign__form {
        max-width: 320px;
        & .forgot__pass {
          margin-top: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          & > a {
            min-height: 40px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            text-decoration: none;
            color: #0e7d7d;
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            transition: 0.4s ease all;
            &:hover {
              opacity: 0.7;
            }
          }
        }
        & .button__guest {
          width: 100%;
          max-width: 320px;
          margin-bottom: 10px;
          & > a {
            display: inline-flex;
            width: 100%;
            min-height: 40px;
            border-radius: 3px;
            background: #ebf1f1;
            border: 1px solid #0e7d7d;
            border-radius: 3px;
            justify-content: center;
            align-items: center;
            color: #0e7d7d;
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            transition: 0.4s ease all;
            &:hover {
              color: #fff;
              background-color: #094f4f;
            }
            &:active {
              background-color: #094f4f;
            }
            &:focus {
              color: #ffffff;
              background-color: #0a5858;
              box-shadow: 0 0 0 0.2rem rgba(14, 125, 125, 0.5);
            }
          }
        }
        & .field__submit {
          width: 100%;
          max-width: 320px;
          margin-bottom: 10px;
          & > input[type='submit'] {
            width: 100%;
            height: 40px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background: #0e7d7d;
            border-radius: 3px;
            font-size: 14px;
            line-height: 20px;
            font-weight: bold;
            color: #fff;
            text-decoration: none;
            cursor: pointer;
            transition: 0.4s ease all;
            &:hover {
              background-color: #0a5858;
            }
            &:active {
              background-color: #094f4f;
            }
            &:focus {
              color: #ffffff;
              background-color: #0a5858;
              box-shadow: 0 0 0 0.2rem rgba(14, 125, 125, 0.5);
            }
          }
        }
        & .sign__error {
          margin-bottom: 15px;
          & > p {
            font-size: 14px;
            color: #b13634;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            & > span {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              margin-right: 10px;
            }
          }
        }
        & .sign__head {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 20px;
          & > h2 {
            margin: 0px;
            margin-right: 12px;
            font-size: 18px;
            line-height: 24px;
            color: #2d2d2d;
            font-weight: bold;
          }
          & > p {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: #2d2d2d;
          }
        }
      }
      width: 100%;
      padding: 30px;
      background: #ffffff;
      box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      & .field__checkbox {
        margin-top: 15px;
      }
      min-height: 520px;
      & .guest__checkout {
        & .guest__business {
          max-width: 320px;
          width: 100%;
        }
        & .guest__private {
          max-width: 320px;
          width: 100%;
          & .field__checkbox {
            margin-top: 15px;
          }
        }
        & .guest__switcher {
          padding: 3px;
          width: 100%;
          max-width: 320px;
          background: #f5f5f5;
          border-radius: 7px;
          margin-bottom: 15px;
          & ul {
            padding: 0px;
            list-style-type: none;
            margin: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            & > li {
              display: flex;
              width: 100%;
              margin-right: 5px;
              justify-content: center;
              align-items: center;
              &.current__switch {
                & > a {
                  background-color: #0e7d7d;
                  color: #fff;
                }
              }
              & > a {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                min-height: 30px;
                border-radius: 5px;
                width: 100%;
                transition: 0.4s ease all;
                &:hover {
                  background-color: #0e7d7d;
                  color: #fff;
                }
              }
              &:last-child {
                margin-right: 0px;
              }
            }
          }
        }
        & .guest__head {
          margin-bottom: 35px;
          & > p {
            margin: 0px;
            color: #000;
            font-size: 14px;
            font-weight: 400;
            & a {
              font-weight: 600;
              text-decoration: underline;
              color: #0e7d7d;
            }
          }
          & > h6 {
            margin: 0px;
            margin-bottom: 3px;
            color: #2d2d2d;
            font-size: 18px;
            line-height: 24px;
            font-weight: bold;
          }
        }
      }
    }
    & > .guest__content--side {
      min-width: 330px;
      margin-left: 20px;
      max-width: 330px;
      & .guest__security {
        margin-top: 20px;
        background: #e7f2f2;
        border-radius: 5px;
        padding: 15px 25px;
        & .security__image {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          & > img {
            max-width: 100px;
          }
        }
        & .security__info {
          margin-bottom: 10px;
          & > p {
            margin: 0px;
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
            color: #2d2d2d;
          }
        }
        & > .guest__security--head {
          & > h2 {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 0px;
            font-size: 14px;
            line-height: 24px;
            color: #0e7d7d;
            font-weight: bold;
            & span {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              margin-right: 15px;
            }
          }
          margin-bottom: 8px;
        }
      }
      & .guest__summary {
        width: 100%;
        & .summary__total {
          & > .summary__total--button {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            & > a {
              display: inline-flex;
              width: 100%;
              align-items: center;
              justify-content: center;
              min-height: 40px;
              background: #0e7d7d;
              box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
              border-radius: 3px;
              font-size: 14px;
              line-height: 20px;
              font-weight: 600;
              color: #fff;
              text-decoration: none;
              transition: 0.4s ease all;
              &:active {
                background-color: #094f4f;
              }
              &:focus {
                color: #ffffff;
                background-color: #0a5858;

                box-shadow: 0 0 0 0.2rem rgba(14, 125, 125, 0.5);
              }
              &:hover {
                background-color: #0a5858;
              }
              & span {
                color: rgba(255, 255, 255, 0.5);
                margin-right: 4px;
              }
            }
          }
          & > .summary__total--info {
            display: flex;
            align-items: flex-start;
            margin-bottom: 20px;
            & .summary__total--price {
              text-align: right;
              & > span {
                display: inline-flex;
                margin-bottom: 5px;
                font-size: 16px;
                line-height: 20px;
                font-weight: bold;
                color: rgba(14, 125, 125, 1);
              }
              & > p {
                margin: 0px;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                color: rgba(45, 45, 45, 0.5);
                & span {
                  color: #2d2d2d;
                }
              }
            }
            & > span {
              margin-right: 15px;
              white-space: nowrap;
              font-size: 16px;
              line-height: 20px;
              font-weight: bold;
              color: #2d2d2d;
            }
            & > p {
              margin: 0px;
              font-size: 14px;
              line-height: 20px;
              color: #9e9e9e;
              font-weight: 600;
            }
            justify-content: space-between;
          }
        }
        & .summary__total {
          & .summary__total--button {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            & > .summary__back--button {
              margin-top: 10px;
              width: 100%;
              & > a.summary__back {
                width: 100%;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                min-height: 40px;
                background: #ebf1f1;
                border-radius: 3px;
                font-weight: 600;
                transition: 0.4s ease all;
                &:hover {
                  opacity: 0.7;
                }
                color: #0e7d7d;
                & span {
                  color: #7db7b7;
                }
              }
            }
          }
        }
        & .summary__project {
          & .summary__element {
            position: relative;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 15px;
            padding-bottom: 0px;
            &:last-child {
              border-bottom: 1px solid #e7e7e7;
              padding-bottom: 15px;
            }
            & > .summary__right {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              & > p {
                white-space: nowrap;
                margin: 0px;
                margin-right: 10px;
                font-size: 14px;
                line-height: 20px;
                color: #2d2d2d;
                font-weight: bold;
              }
              & > .summary__remove {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                min-width: 14px;
                max-width: 14px;
                min-height: 14px;
                max-height: 14px;
                & > a {
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
            & > .summary__left {
              margin-right: 10px;
              & > h2 {
                margin-top: 0px;
                margin-bottom: 5px;
                font-size: 14px;
                line-height: 20px;
                color: #2d2d2d;
              }
              & > ul {
                padding: 0px;
                list-style-type: none;
                margin: 0px;
                & > li {
                  color: #2d2d2d;
                  font-size: 14px;
                  line-height: 20px;
                  font-weight: 400;
                  margin-bottom: 5px;
                  &:last-child {
                    margin-bottom: 0px;
                  }
                }
              }
            }
          }
          & .summary__empty {
            border-bottom: 1px solid #e7e7e7;
            margin-bottom: 15px;
            min-height: 80px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;
            & > p {
              margin: 0px;
              font-size: 14px;
              line-height: 20px;
              color: #2d2d2d;
              font-weight: bold;
            }
          }
        }
        & .summary__head {
          margin-bottom: 15px;
          border-bottom: 1px solid #e7e7e7;
          padding-bottom: 15px;
          & > h2 {
            text-align: center;
            margin: 0px;
            font-size: 16px;
            line-height: 24px;
            color: #0e7d7d;
            font-weight: bold;
          }
        }
      }
      & > .guest__summary {
        width: 100%;
        padding: 15px 20px;
        padding-bottom: 30px;
        background: #ffffff;
        box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
      }
    }
  }
}
.pair__picker {
  margin-bottom: 20px;
  & .pair__container {
    width: 100%;
    & .upload__block.upload-content__drag-drop__old {
      max-width: 100%;
    }
    & .upload__block.upload-content__files {
      max-width: 100%;
      padding-top: 0px;
    }
    & > .head__pair {
      padding: 20px;
      border-bottom: 1px solid #9d9d9d;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > .pair__remove {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        & > a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          & > img {
            min-width: 18px;
          }
        }
      }
      & > .pair__double {
        display: flex;
        align-items: center;
        width: 100%;
        & .pair__elem {
          width: 100%;
          max-width: 215px;
          & .group__tag--checkbox {
            margin-bottom: 0px;
            & > .group__target--container {
              position: relative;
              &:before {
                content: '';
                position: absolute;
                right: 10px;
                width: 12px;
                height: 15px;
                background: url('../images/servicearrow.svg');
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                top: 50%;
                transform: translate(0%, -50%);
              }
              border: 0px;
              background: none;
              background-color: #fff;
            }
          }
          & .select__dropdown {
            margin-top: 0px;
          }
          & .select__label {
            background-color: #fff;
            &:before {
              background-color: #fff;
            }
          }
        }
        justify-content: flex-start;
        & > .pair__spacer {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin: 0px 15px;
          & > img {
            min-width: 14px;
          }
        }
      }
    }
    background: #f7f7f7;
    border-radius: 10px;
  }
}
.lang__pair {
  & .one__more--package {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    & > a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 10px 20px;
      background: #ffffff;
      border: 1px solid #0e7d7d;
      box-sizing: border-box;
      box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
      border-radius: 3px;
      font-size: 14px;
      line-height: 21px;
      color: #0e7d7d;
      font-weight: bold;
      text-decoration: none;
      transition: 0.4s ease all;
      &:hover {
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      }
      &:active,
      &:focus {
        border-color: rgba(14, 125, 125, 0.3);
      }
      & > span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
      }
      & > img {
        margin-right: 10px;
        min-width: 14px;
      }
    }
  }
}
.info__tooltip--block {
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px 10px 10px 0px;
  padding: 12px;
  min-width: 190px;
  max-width: 190px;
  background-color: #fff;
  position: absolute;
  display: none;
  left: 50%;
  transform: translate(-50%, -100%);
  z-index: 5;
  margin-top: -90px;
  & > p {
    font-size: 13px;
    color: #000;
    font-weight: 400;
  }
}
.thank__guest {
  width: 100%;
  background: #ffffff;
  min-height: calc(100vh - 110px);
  box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding-top: 60px;
  padding-bottom: 85px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  & .thank__go--order {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > p {
      margin: 0px;
      color: #2d2d2d;
      font-size: 14px;
      font-weight: 400;
      & a {
        font-weight: 600;
        text-decoration: underline;
        color: #0e7d7d;
      }
      line-height: 21px;
    }
    & > a {
      width: 100%;
      max-width: 290px;
      min-height: 40px;
      background: #0e7d7d;
      box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05), 0px 4px 5px rgba(0, 0, 0, 0.05);
      border-radius: 3px;
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      font-weight: bold;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
  & .thank__controls {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 560px;
    margin-left: auto;
    margin-right: auto;
    & > a {
      display: inline-flex;
      align-items: center;
      width: 100%;
      font-size: 14px;
      line-height: 21px;
      color: rgba(14, 125, 125, 1);
      font-weight: 600;
      text-decoration: none;
      transition: 0.4s ease all;
      min-height: 40px;
      border: 1px solid #0e7d7d;
      border-radius: 4px;
      &:focus {
      }
      &:hover {
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      }
      &:nth-child(1) {
        margin-right: 15px;
        &:last-child {
          margin-right: 0px;
        }
      }
      justify-content: center;
      & > span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 13px;
      }
    }
  }
  & > .thank__step--one {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  & .thank__faq {
    max-width: 560px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 20px;
    width: 100%;
    & > h6 {
      text-align: center;
      margin-top: 0px;
      margin-bottom: 10px;
      font-size: 24px;
      line-height: 32px;
      color: rgba(14, 125, 125, 1);
      font-weight: 600;
    }
    & > .thank__faq--elem {
      margin-bottom: 5px;
      width: 100%;
      &.faq__active {
        & .thank__plus {
          opacity: 0 !important;
        }
      }
      & > .thank__faq--content {
        padding: 25px 30px;
        background: #f3f3f3;
        text-align: left;
        padding-top: 10px;
        display: none;
        width: 100%;
        & > p {
          margin: 0px;
          color: #2d2d2d;
          font-size: 16px;
          line-height: 24px;
        }
      }
      & .thank__faq--title {
        display: flex;
        background: #f3f3f3;
        cursor: pointer;
        align-items: center;
        padding-top: 15px;
        padding-bottom: 15px;
        width: 100%;
        padding-left: 30px;
        padding-right: 15px;
        & > .thank__state {
          margin-left: 12px;
          min-width: 34px;
          max-width: 34px;
          min-height: 34px;
          border-radius: 50px;
          max-height: 34px;
          background: #ffffff;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.03);
          position: relative;
          & > .thank__plus {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            min-height: 16px;
            min-width: 2px;
            background-color: #589999;
            transition: 0.3s ease all;
          }
          & > .thank__minus {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            min-width: 16px;
            min-height: 2px;
            background-color: #589999;
            transition: 0.3s ease all;
          }
        }
        & > p {
          margin: 0px;
          text-transform: uppercase;
          color: rgba(29, 28, 25, 1);
          text-align: left;
          font-size: 14px;
          line-height: 19px;
          font-weight: 600;
        }
        justify-content: space-between;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
    & .thank__faq--head {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & > p {
        margin: 0px;
        color: rgba(45, 45, 45, 1);
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
  & .thank__questions {
    text-align: center;
    & > p {
      margin: 0px;
      font-size: 14px;
      line-height: 21px;
      color: #2d2d2d;
      font-weight: 400;
      & a {
        font-weight: 600;
        text-decoration: underline;
        color: #0e7d7d;
      }
    }
  }
  & .thank__final--download {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 330px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 35px;
    & > a {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: 100%;
      font-size: 14px;
      line-height: 21px;
      color: #0e7d7d;
      font-weight: 600;
      text-decoration: none;
      -webkit-transition: 0.4s ease all;
      transition: 0.4s ease all;
      min-height: 40px;
      border: 1px solid #0e7d7d;
      border-radius: 4px;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      &:hover {
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      }
      & span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
      }
    }
  }
  & .thank__review {
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;
    & > p {
      margin-top: 0px;
      margin-bottom: 35px;

      font-size: 18px;
      line-height: 24px;
      color: rgba(45, 45, 45, 1);
      font-weight: 400;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  & .thank__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > p {
      margin: 0px;
      padding: 15px 20px;
      background: #e7f2f2;
      border-radius: 5px;
      font-size: 18px;
      line-height: 24px;
      color: rgba(14, 125, 125, 1);
      text-align: center;
      font-weight: 600;
    }
    & > h2 {
      text-align: center;
      margin-top: 0px;
      margin-bottom: 15px;
      font-size: 26px;
      line-height: 35px;
      color: rgba(14, 125, 125, 1);
      font-style: bold;
    }
  }
  & .thank__image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
}
.guest__content--main {
  & .payment__method {
    width: 100%;
    max-width: 420px;
    & .payment__method--grid {
      width: 100%;
      background: #f7f8fa;
      border: 1px solid #e3e3e3;
      border-radius: 4px;
      overflow: hidden;
      & > .elem__payment--method {
        padding: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e3e3e3;
        & .radio__box {
          margin-right: 0px;
        }
        & > p {
          display: inline-flex;
          align-items: center;
          font-size: 14px;
          line-height: 20px;
          color: rgba(120, 120, 120, 1);
          font-weight: 600;
          margin: 0px;
          justify-content: flex-start;
          margin-right: 10px;
          & > span {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            min-width: 52px;
            min-height: 35px;
            margin-right: 10px;
          }
        }
        & .delayed {
          display: flex;
          flex-direction: column;
          & > p {
            text-align: left;
            cursor: pointer;
            margin: 0;
            padding: 0;
            box-sizing: border-box;
            font-family: 'Open Sans', sans-serif;
            margin-top: 0px;
            margin-bottom: 4px;
            font-size: 14px;
            line-height: 20px;
            color: #121212;
            font-weight: 600;
          }
        }
      }
    }
    & > h2 {
      margin-top: 0px;
      margin-bottom: 30px;
      font-size: 18px;
      line-height: 24px;
      color: rgba(45, 45, 45, 1);
      font-weight: bold;
    }
  }
}
.guest__payment--method {
  & > h2 {
    margin-top: 0px;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 24px;
    color: rgba(45, 45, 45, 1);
    font-weight: bold;
  }
  & > .timeline__block {
    margin-top: 30px;
    & > .choose__timeline--grid {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      & > .elem__choose--timeline {
        &.active__choose--timeline {
          background-color: rgba(14, 125, 125, 1);
          & .info__tooltip {
            & svg {
              & circle {
                fill: rgba(0, 0, 0, 0.25);
              }
            }
          }
          & > p {
            & path {
              fill: #fff;
            }
            color: #fff;
            &.deadline {
              color: #fff;
            }
          }
          & .checkbox {
            & .checkbox__checkmark {
              border-color: #fff;
              background-color: #fff;
              &:before {
                background-image: url('../images/checkgreen.svg');
                background-size: 10px 8px;
                background-repeat: no-repeat;
                background-position: center;
              }
            }
          }
        }
        & > .checkbox {
          margin-right: 10px;
        }
        padding: 5px 15px;
        cursor: pointer;
        border: 1px solid #0e7d7d;
        box-sizing: border-box;
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
        border-radius: 3px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        min-height: 40px;
        justify-content: flex-start;
        & .info__tooltip {
          & circle {
            transition: 0.3s ease all;
          }
        }
        & p {
          &.deadline {
            font-weight: 400;
            color: rgba(59, 59, 59, 1);
          }
          color: rgba(14, 125, 125, 1);
          font-size: 14px;
          line-height: 14px;
          font-weight: 600;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          & > span {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
          }
        }
        &:last-child {
          margin-right: 0px;
        }
      }
    }
    & > h6 {
      margin: 0px;
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 24px;
      color: rgba(59, 59, 59, 1);
      font-weight: 600;
    }
  }
  & > .chosen__element {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 605px;
    padding: 15px 20px;
    background: #f3f9f9;
    margin-bottom: 30px;
    & > .chosen__info--help {
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > ul {
        min-width: 165px;
        & > li {
          margin-bottom: 10px;
          & > p {
            margin: 0px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 14px;
            line-height: 18px;
            color: rgba(59, 59, 59, 1);
            font-weight: 600;
            & > span {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              margin-right: 10px;
              & > img {
                min-width: 12px;
              }
            }
          }
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
      & > h2 {
        margin: 0px;
        font-size: 20px;
        line-height: 27px;
        font-weight: bold;
        white-space: nowrap;
        margin-left: 15px;
      }
    }
    & > .chosen__info--left {
      min-width: 200px;
      & > h2 {
        margin-top: 0px;
        margin-bottom: 10px;
        font-size: 20px;
        line-height: 27px;
        color: rgba(14, 125, 125, 1);
        font-weight: bold;
      }
      & > p {
        margin: 0px;
        font-size: 14px;
        line-height: 20px;
        color: rgba(59, 59, 59, 1);
        font-weight: 400;
      }
    }
    & > .spacer {
      height: 55px;
      margin: 0px 15px;
      min-width: 1px;
      display: inline-flex;
      background-color: #97c7c7;
    }
    & > .chosen__info--left {
      max-width: 200px;
    }
  }
}
.brief__wrapper {
  & .upload-content__drag-drop.upload-content__drag-drop__old.upload__block {
    position: relative;
    & .supported__files {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 12px;
      bottom: 12px;
      & > p {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 12px;
        line-height: 18px;
        font-weight: 600;
        color: rgba(157, 157, 157, 1);
        & span {
          margin-top: 0px;
          margin-bottom: 0px;
          margin-left: 10px;
        }
      }
    }
  }
  & .form-group {
    & textarea {
      width: 100%;
      height: 80px;
      background: #f2f2f2;
      border-radius: 3px;
      border-color: #f2f2f2;
    }
    & .form-group__header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      & > div {
        margin-left: 7px;
      }
      & > p {
        margin: 0px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        color: #2d2d2d;
        & span {
          opacity: 0.4;
        }
      }
    }
  }
  & .improve__field {
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 500px;
    & > h6 {
      margin-top: 0px;
      margin-bottom: 10px;
      color: #2d2d2d;
      font-size: 14px;
      line-height: 20px;
      font-weight: bold;
      & span {
        opacity: 0.4;
      }
    }
  }
  & > h2 {
    margin-bottom: 20px;
    margin-top: 0px;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    color: rgba(45, 45, 45, 1);
  }
}
.picked__select {
  display: none !important;
}
.select__v--tags {
  & .select__dropdown {
    margin-top: 0px;
  }
  & .v__tags--block {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    & .v__tag--element {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 15px;
      background: #ffffff;
      border: 1px solid #0e7d7d;
      border-radius: 2px;
      box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
      margin-right: 10px;
      margin-bottom: 7px;
      &:last-child {
        margin-right: 0px;
      }
      & p {
        margin: 0px;
        font-size: 12px;
        line-height: 14px;
        font-weight: 600;
        color: rgba(59, 59, 59, 1);
      }
      & a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
      }
    }
  }
}
.additional__guest {
  & .additional__list {
    & .additional__element {
      width: 100%;
      max-width: 570px;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 80px;
      background: #ffffff;
      border: 1px solid #e3e3e3;
      border-radius: 10px;
      position: relative;
      margin-bottom: 10px;
      &.added__additional {
        max-width: 660px;
      }
      & > .additional__right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: 10px;
        & > .price__additional {
          & > p {
            min-height: 35px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 7px 10px;
            font-size: 14px;
            line-height: 20px;
            color: rgba(14, 125, 125, 1);
            font-weight: 600;
            background-color: rgba(231, 242, 242, 1);
            border-radius: 3px;
            text-align: center;
          }
        }
        & > .additional__controls {
          margin-left: 10px;
          & > a {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            min-width: 95px;
            min-height: 35px;
            background: #ffffff;
            border: 1px solid #0e7d7d;
            border-radius: 3px;
            font-size: 14px;
            line-height: 14px;
            font-weight: 600;
            min-height: 35px;
            transition: 0.4s ease all;
            color: rgba(14, 125, 125, 1);
            &:hover {
              box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
            }
          }
        }
      }
      & > .additional__left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        & > .additional__left--text {
          & > h6 {
            margin-top: 0px;
            font-size: 14px;
            line-height: 19px;
            color: #0e7d7d;
            font-weight: bold;
          }
          & p {
            margin: 0px;
            font-size: 12px;
            line-height: 16px;
            color: #000;
            font-weight: 400;
          }
        }
        & > .additional__left--image {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
        }
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  & > h2 {
    margin-top: 0px;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    color: #2d2d2d;
    & span {
      opacity: 0.4;
    }
  }
}
.additional__complex--controls {
  & > .complex__controls--inner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 10px;
    & > .remove__button {
      & > a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 34px;
        max-width: 34px;
        min-height: 34px;
        max-height: 34px;
        border: 1px solid #e3e3e3;
        border-radius: 3px;
        transition: 0.4s ease all;
        &:hover {
          border: 1px solid #9e9e9e;
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        }
      }
    }
    & > .edit__button {
      margin-right: 10px;
      & > a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 34px;
        max-width: 34px;
        min-height: 34px;
        max-height: 34px;
        border: 1px solid #e3e3e3;
        border-radius: 3px;
        transition: 0.4s ease all;
        &:hover {
          border: 1px solid #9e9e9e;
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        }
      }
    }
    & > a {
      padding: 7px 15px;
      background: #0e7d7d;
      border: 1px solid #0e7d7d;
      border-radius: 3px;
      min-height: 35px;
      display: flex;
      font-size: 14px;
      line-height: 14px;
      color: rgba(255, 255, 255, 1);
      font-weight: 600;
      align-items: center;
      justify-content: flex-start;
      margin-right: 10px;
      transition: 0.4s ease all;
      &:hover {
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      }
      & > span {
        display: inline-flex;
        margin-right: 10px;
      }
    }
  }
}
.additional__copies--container {
  margin-top: 20px;
  width: 100%;
  & .copies__container--wrapper {
    width: 100%;
    border-top: 1px solid rgba(227, 227, 227, 1);
    border-bottom: 1px solid rgba(227, 227, 227, 1);
    & > .element__copy {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(227, 227, 227, 1);
      padding: 10px 15px;
      & .uncheck__file {
        margin-right: 10px;
        & p {
          display: flex;
          align-items: center;
          margin: 0px;
          font-size: 12px;
          line-height: 18px;
          word-break: break-all;
          font-weight: 400;
          justify-content: flex-start;
          & span {
            margin-right: 10px;
          }
        }
      }
      & .number__copies {
        display: flex;
        align-items: center;
        justify-content: center;
        & > .select {
          & .select__dropdown {
            margin-top: 0px;
          }
          & .select__label {
            &:after {
              right: 7px;
            }
            background-color: #fff;
            border: 1px solid #e3e3e3;
            border-radius: 3px;
            &:before {
              background-color: transparent;
            }
          }
          min-width: 55px;
          max-width: 55px;
        }
        & > p {
          margin: 0px;
          white-space: nowrap;
          font-size: 12px;
          line-height: 18px;
          color: rgba(59, 59, 59, 1);
          margin-right: 12px;
          font-weight: 400;
          & span {
            & img {
              min-width: 24px;
            }
          }
        }
      }
      &:last-child {
        border-bottom: 0px;
      }
    }
  }
}
.element__copy.copy__clone {
  display: none !important;
}
.float__switcher.works__files {
  margin-top: 40px;
}
.works__files--content {
  margin-top: 20px;
  width: 100%;
  & > .files__wrapper {
    position: relative;
    & .supported__files {
      position: absolute;
      right: 15px;
      bottom: 12px;
      & > p {
        margin: 0px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 12px;
        line-height: 18px;
        color: rgba(157, 157, 157, 1);
        font-weight: 600;
        & span {
          margin-top: 0px;
          margin-bottom: 0px;
          margin-left: 10px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
.modal__regular.modal__additional--service {
  & > a {
    right: 545px;
  }
  & > .inner__modal--regular {
    max-width: 530px;
  }
}
.additional__float {
  width: 100%;
  z-index: 3;
  & .uncheck__document {
    margin-top: 40px;
    width: 100%;
    & .catcher__uncheck {
      width: 100%;
      overflow-x: auto;
    }
    & .uncheck__list {
      margin-top: 20px;
      border-top: 1px solid #e3e3e3;
      border-bottom: 1px solid #e3e3e3;
      width: 100%;
      & .elem__uncheck {
        display: flex;
        padding: 10px;
        border-bottom: 1px solid #e3e3e3;
        &.unchecked {
          opacity: 0.4;
          & .uncheck__file {
            position: relative;
            &:before {
              content: '';
              position: absolute;
              left: 0px;
              width: 100%;
              height: 1px;
              background-color: #787878;
              top: 50%;
              transform: translate(0%, -50%);
            }
          }
        }
        & .uncheck__check {
          min-width: 30px;
          margin-right: 15px;
        }
        & .uncheck__file {
          width: 100%;
          & p {
            display: flex;
            align-items: center;
            word-break: break-all;
            font-size: 12px;
            line-height: 12px;
            font-weight: 400;
            margin: 0px;
            justify-content: flex-start;
            & span {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              margin-right: 10px;
              & > img {
                min-width: 24px;
                max-width: 24px;
              }
            }
          }
        }
        &:last-child {
          border-bottom: 0px;
        }
        align-items: center;
        justify-content: flex-start;
      }
    }
    & > span {
      font-size: 12px;
      line-height: 16px;
      color: rgba(158, 158, 158, 1);
      font-weight: bold;
    }
    & > p {
      margin: 0px;
      margin-bottom: 0px;

      font-size: 14px;
      line-height: 19px;
      color: rgba(45, 45, 45, 1);
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      & span {
        margin-left: 5px;
      }
    }
  }
  & .additional__copies {
    margin-top: 40px;
    & > p {
      margin: 0px;
      margin-bottom: 0px;

      font-size: 16px;
      line-height: 21px;
      color: rgba(45, 45, 45, 1);
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;
      & span {
        margin-left: 10px;
      }
    }
  }
  & .float__controls {
    margin-top: 60px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    & > .float__cancel {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 125px;
      min-height: 45px;
      border: 1px solid #0e7d7d;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      font-size: 14px;
      line-height: 14px;
      font-weight: 600;
      transition: 0.4s ease all;
      color: rgba(14, 125, 125, 1);
      &:active,
      &:focus {
        border-color: rgba(14, 125, 125, 0.3);
      }
      &:hover {
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      }
    }
    & > .float__add {
      margin-right: 15px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 110px;
      min-height: 45px;
      background: #0e7d7d;
      box-sizing: border-box;
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
      border-radius: 3px;
      transition: 0.4s ease all;
      font-size: 14px;
      line-height: 14px;
      color: rgba(255, 255, 255, 1);
      font-weight: 600;
      &:hover {
        background: #076969;
      }
    }
  }
  & > .float__inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    & .float__switcher {
      & > p {
        margin-top: 0px;
        font-size: 16px;
        line-height: 21px;
        color: rgba(45, 45, 45, 1);
        font-weight: bold;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        & span {
          margin-left: 10px;
          & img {
            min-width: 15px;
          }
        }
      }
    }
    & > .cost__info {
      padding: 15px;
      background: #e7f2f2;
      border-radius: 40px;
      margin-bottom: 20px;
      & > p {
        margin: 0px;
        font-size: 14px;
        line-height: 14px;
        font-weight: 400;
        & span {
          font-weight: bold;
        }
      }
    }
    & > .document__certif--title {
      margin-bottom: 20px;
      & > p {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 18px;
        line-height: 24px;
        color: rgba(45, 45, 45, 1);
        font-weight: bold;
        margin: 0px;
        & > span {
          display: inline-flex;
          margin-left: 10px;
        }
      }
    }
  }
}

header {
  &.simplified__header {
    & .outer__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 15px;
      padding-right: 15px;
      & .header__info {
        & > p {
          margin: 0px;
          font-size: 13px;
          line-height: 17px;
          font-weight: 400;
          & a {
            color: rgba(14, 125, 125, 1);
            font-weight: bold;
            text-decoration: underline;
          }
          & span {
            font-weight: bold;
          }
        }
      }
    }
  }
}
.simplified__block {
  & .translation__correct--wrapper {
    & .information__checkbox {
      margin-bottom: 20px;
    }
    & .translation__correct--field {
      max-width: 400px;
      width: 100%;
      & .field__custom {
        width: 100%;
        & > textarea {
          width: 100%;
          height: 80px;
          background: #f2f2f2;
          border-radius: 3px;
          resize: none;
          padding: 10px;
        }
        & > p {
          margin-top: 0px;
          margin-bottom: 12px;
          font-size: 14px;
          line-height: 20px;
          font-weight: 600;
          color: rgba(45, 45, 45, 1);
        }
      }
    }
    & > h2 {
      margin-top: 0px;
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 24px;
      color: rgba(45, 45, 45, 1);
      font-weight: bold;
    }
  }
  & .summary__project--info {
    margin-bottom: 20px;
    & ul {
      & > li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 14px;
        font-weight: 400;
        color: rgba(45, 45, 45, 1);
        &:last-child {
          margin-bottom: 0px;
        }
        & > span {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          & img {
            min-width: 14px;
          }
        }
      }
    }
  }
  & .get__started {
    & .started__form {
      max-width: 320px;
      margin-bottom: 30px;
    }
    & .started__note {
      max-width: 510px;
      padding: 15px;
      & > p {
        margin: 0px;
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
        & span {
          font-weight: bold;
        }
      }
      & > a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 200px;
        min-height: 40px;
        background: #ebf1f1;
        border-radius: 3px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        color: rgba(14, 125, 125, 1);
        text-decoration: none;
        transition: 0.4s ease all;
        &:hover {
          opacity: 0.7;
        }
      }
      border: 1px solid #e3e3e3;
      border-radius: 10px;
    }
    & .head__started {
      margin-bottom: 30px;
      & > h2 {
        margin-top: 0px;
        margin-bottom: 5px;
        font-size: 18px;
        line-height: 24px;
        color: rgba(45, 45, 45, 1);
        font-weight: bold;
      }
      & > p {
        margin: 0px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: rgba(45, 45, 45, 1);
      }
    }
  }
  & .summary__project {
    & .summary__estimation {
      min-height: 90px;
      margin-bottom: 15px;
      border-bottom: 1px solid #e7e7e7;
      & > p {
        margin: 0px;
        font-size: 14px;
        line-height: 20px;
        color: rgba(45, 45, 45, 1);
        font-weight: bold;
      }
    }
  }
  & .summary__total--info {
    & > p {
      &.summary__empty {
        color: rgba(14, 125, 125, 1) !important;
      }
    }
  }
  & .element__progress.done__progress {
    background-color: #e7f2f2 !important;
  }
}

.checkout__long.simplified__block {
  & .summary__empty {
    padding-right: 10px !important;
  }
  & .summary__element > .summary__left > ul > li {
    color: rgba(158, 158, 158, 1) !important;
  }
  & .summary__project--wrapper {
    min-height: 105px;
    border-bottom: 1px solid rgba(231, 231, 231, 1);
    margin-bottom: 15px;
    & .summary__element {
      & > .summary__left {
        & > h2 {
          margin-bottom: 0px !important;
        }
        & > ul {
          & > li {
            & > span {
              display: inline-flex;
              margin-top: 2px;
              font-size: 14px;
              line-height: 20px;
              color: rgba(45, 45, 45, 1);
              font-weight: bold;
            }
            & > p {
              margin: 0px;
              font-size: 14px;
              line-height: 20px;
              color: rgba(157, 157, 157, 1);
              & span {
                font-weight: bold;
              }
            }
          }
        }
      }
      & .summary__right > p {
        margin-right: 0px !important;
        padding-right: 10px;
      }
      &:last-child {
        margin-bottom: 0px !important;
      }
      border-bottom: 0px !important;
      &:nth-child(1) {
        border-bottom: 0px !important;
      }
    }
  }
}
.summary__total--info.filled {
  padding-right: 10px;
  // margin-top:65px;
  // padding-top:15px;
  // border-top:1px solid #E7E7E7;
  & > p {
    font-size: 16px !important;
    line-height: 20px !important;
    color: rgba(14, 125, 125, 1) !important;
    font-weight: bold !important;
  }
}
.translation__wrapper--picker {
  & .translation__picker--element {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0px;
    }
    & .picker__choose--grid {
      display: flex;
      justify-content: center;
      & > .elem__choose--grid {
        & .choose__grid--switch {
          padding-top: 8px;
        }
        border: 2px solid #e3e3e3;
        width: 100%;
        padding: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-right: -2px;
        transition: 0.4s ease all;
        position: relative;
        cursor: pointer;
        &.active__choose--grid {
          z-index: 2;
          border-color: rgba(14, 125, 125, 1);
        }
        & > .choose__grid--info {
          & > p {
            margin: 0px;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: rgba(59, 59, 59, 1);
            & span {
              font-weight: 600;
            }
          }
          & > h6 {
            min-height: 33px;
            & > p {
              font-size: 14px;
              line-height: 22px;
              font-weight: bold;
              min-height: 38px;
              margin-right: 5px;
              margin-top: 0px;
              margin-bottom: 0px;
              min-height: 0px;
            }
            margin-top: 0px;
            margin-bottom: 10px;

            align-items: center;
            flex-wrap: wrap;
            vertical-align: center;
            display: flex;
            & span {
              padding: 3px 10px;
              background: rgba(235, 241, 241, 1);
              border-radius: 5px;
              color: rgba(99, 176, 176, 1);
              font-weight: 600;
              margin-left: 5px;
              margin: 5px 3px;
              margin-left: 0px;
              display: inline-block;
            }
            color: rgba(14, 125, 125, 1);
          }
        }
        &:last-child {
          border-bottom-right-radius: 10px;
          border-top-right-radius: 10px;
        }
        &:nth-child(1) {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
      }
    }
    & .translation__picker--title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 10px;
      & > span {
        display: inline-flex;
        width: 100%;
        border-radius: 5px;
        height: 1px;
        background-color: #e7e7e7;
      }
      & > h2 {
        margin: 0px;
        margin-right: 15px;
        white-space: nowrap;
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
        color: rgba(45, 45, 45, 1);
      }
    }
  }
}
.shipping__information--section {
  & .shipping__form {
    max-width: 550px;
    width: 100%;
    & form {
      & > .phone__group {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        & > .field__custom {
          &:nth-child(1) {
            width: 100%;
            max-width: 260px;
          }
          &:nth-child(2) {
            max-width: 280px;
          }
          margin-right: 10px;
          margin-bottom: 0px;
          &:last-child {
            margin-right: 0px;
          }
        }
      }
      & > .city__group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        & > .field__custom {
          margin-right: 10px;
          & .select__dropdown {
            margin-top: 0px !important;
          }
          margin-bottom: 0px;
          & .select__label {
            background-color: #f5f5f5;
            & span {
              font-weight: 600 !important;
            }
          }
          &:nth-child(3) {
            margin-right: 0px;
          }
          &:nth-child(2) {
            width: 100%;
            max-width: 210px;
          }
          &:nth-child(1) {
            width: 100%;
            max-width: 200px;
          }
        }
      }
      & > .adress__group {
        display: flex;
        margin-bottom: 10px;
        & > .field__custom {
          margin-bottom: 0px;
          &:nth-child(1) {
            width: 100%;
            max-width: 320px;
            margin-right: 10px;
          }
          &:nth-child(2) {
            max-width: 220px;
            width: 100%;
          }
        }
      }
      & > .field__custom {
        margin-bottom: 10px;
      }
    }
  }
  & .shipping__information--head {
    margin-bottom: 20px;
    & > h2 {
      margin-top: 0px;
      margin-bottom: 4px;
      font-size: 18px;
      line-height: 24px;
      color: rgba(45, 45, 45, 1);
      font-weight: bold;
    }
    & > p {
      margin: 0px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: rgba(45, 45, 45, 1);
    }
  }
}
.simplified__block {
  & .payment__method {
    margin-top: 30px;
    & .payment__method--grid {
      & > .elem__payment--method {
        padding: 10px 15px;
        padding-left: 10px;
      }
    }
  }
}
.order__confirm {
  min-height: 760px;
  padding-top: 25px;
  padding-bottom: 40px;
  width: 100%;
  background: #ffffff;
  box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
  padding-right: 15px;
  flex-direction: column;
  & > .order__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > .order__details {
      margin-top: 30px;
      & .back__order {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        & > a {
          align-items: center;
          justify-content: center;
          width: 100%;
          max-width: 290px;
          min-height: 40px;
          font-size: 14px;
          line-height: 20px;
          color: rgba(255, 255, 255, 1);
          font-weight: bold;
          text-decoration: none;
          display: inline-flex;
          background: #0e7d7d;
          box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05), 0px 4px 5px rgba(0, 0, 0, 0.05);
          border-radius: 3px;
          transition: 0.4s ease all;
          &:hover {
            opacity: 0.7;
          }
        }
      }
      & > span {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        line-height: 19px;
        color: rgba(45, 45, 45, 1);
        text-align: center;
        font-weight: 400;
        font-style: italic;
        margin-bottom: 45px;
      }
      & > .order__email--wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        & > .order__email {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 15px 20px;
          border: 1px solid #e3e3e3;
          border-radius: 5px;
          & > a {
            font-size: 14px;
            line-height: 20px;
            color: rgba(14, 125, 125, 1);
            font-weight: bold;
            text-transform: uppercase;
            text-decoration: none;
          }
          & > p {
            font-size: 16px;
            line-height: 24px;
            color: rgba(45, 45, 45, 1);
            font-weight: 600;
            margin: 0px;
            margin-right: 10px;
          }
        }
      }

      & > p {
        text-align: center;
        margin: 0px;
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: rgba(45, 45, 45, 1);
      }
    }
    & > p {
      margin: 0px;
      padding: 15px 20px;
      background: #e7f2f2;
      border-radius: 5px;
      text-align: center;
      font-size: 16px;
      line-height: 24px;
      color: rgba(14, 125, 125, 1);
      font-weight: 600;
    }
    & > h2 {
      text-align: center;
      margin-top: 0px;
      margin-bottom: 20px;
      font-size: 26px;
      line-height: 34px;
      color: rgba(14, 125, 125, 1);
      font-weight: bold;
      & span {
        font-weight: 400;
      }
    }
  }
  & > .confirm__image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
    max-width: 405px;
    margin-left: auto;
    margin-right: auto;
    & > img {
      max-width: 405px;
      width: 100%;
    }
  }
}
.simplified__block {
  & .checkout__progress--section .element__progress {
    box-shadow: none;
  }
}
.element__progress--wrapper {
  width: 100%;
  padding: 2px;
  border-radius: 3px;
  background-color: #fff;
  &.done__progress {
    background-color: rgba(14, 125, 125, 0.2);
  }
  &.active__progress {
    background-color: rgba(14, 125, 125, 0.2);
  }
  -webkit-clip-path: polygon(
    0 0,
    calc(100% - 15px) 0%,
    100% 50%,
    calc(100% - 15px) 100%,
    0 99%,
    15px 50%
  );
  clip-path: polygon(0 0, calc(100% - 15px) 0%, 100% 50%, calc(100% - 15px) 100%, 0 99%, 15px 50%);
}

.element__progress--wrapper {
  &:nth-child(1) {
    clip-path: polygon(0 0, calc(100% - 15px) 0%, 100% 50%, calc(100% - 15px) 100%, 0 99%, 0px 50%);
    & > .element__progress {
      clip-path: polygon(
        0 0,
        calc(100% - 15px) 0%,
        100% 50%,
        calc(100% - 15px) 100%,
        0 99%,
        0px 50%
      );
    }
  }
}
.simplified__block {
  & .pair__picker .pair__container > .head__pair > .pair__double {
    & > .pair__elem {
      max-width: 100%;
      & .group__tag--checkbox > .group__target--container {
        padding-left: 15px;
        min-height: 40px;
      }
      & .select__label {
        border-color: #fff !important;
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
        & span {
          color: #75758b !important;
        }
      }
    }
  }
}
.table__wrapper table.table__common tr td {
  & .language__pairs {
    display: flex;
    justify-content: center;
    align-items: center;
    & > p {
      display: flex;
      align-items: center;
      color: #2d2d2d;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      justify-content: center;
      white-space: nowrap;
      & a {
        display: flex;
        align-items: center;
        margin-left: 10px;
        justify-content: flex-start;
      }
      & img {
        max-width: 18px;
      }
    }
  }
}

.double__check--input {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  & > a {
    margin-left: 15px;
    font-size: 16px;
    line-height: 21px;
    color: rgba(63, 136, 197, 1);
    font-weight: 600;
    text-decoration: underline;
  }
}
.employers-page__heading.task__heading > .ordering__form--head {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-right: 0px;
  & .menu__permissions {
    & .permissions-control-dropdown__toggle {
      padding: 0px;
      border-radius: 3px;

      & .burger {
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 44px;
        min-height: 40px;
        border-radius: 5px;
      }
    }
    & > a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 44px;
      min-height: 40px;
      background: #ffffff;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
    }
  }
}
.modal__regular {
  & > a {
    right: calc(100% - 59px);
    opacity: 1;
  }
  & > .inner__modal--regular {
    right: 0px;
    max-width: 100%;
    width: calc(100% - 70px);
    & .price__modal--info {
      & .price__main {
        & .table__wrapper {
          padding-bottom: 10px;
          border-bottom: 1px solid #e3e3e3;
          & .table__common {
            & .padd__text1 {
              margin-right: 160px;
            }
            & .padd__text2 {
              margin-right: 22px;
            }
            & .padd__text3 {
              margin-right: 61px;
            }
            & tr {
              &:nth-child(3) {
                & > td {
                  & > div.source__counter {
                    top: -28px;
                    height: calc(100% + 74px) !important;
                  }
                }
              }
            }
            & td {
              padding-left: 0px;
            }
            & .number__one {
              & > td {
                padding-top: 10px;
                padding-bottom: 7px;
                & span {
                  font-size: 12px;
                  line-height: 18px;
                  color: rgba(45, 45, 45, 1);
                  font-weight: 400;
                }
              }
            }
            & td {
              &.one__more {
                padding-top: 19px !important;
                border-top: 1px solid #e3e3e3;
                padding-bottom: 15px !important;
                & > a {
                  display: inline-flex;
                  align-items: center;
                  justify-content: flex-start;
                  font-size: 14px;
                  line-height: 21px;
                  color: rgba(14, 125, 125, 1);
                  font-weight: 600;
                  & span {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 10px;
                  }
                }
              }
              & .fee__select {
                & .select__label {
                  min-height: 40px;
                  min-width: 85px;
                  border: 1px solid #e3e3e3;
                  border-radius: 3px;
                  padding-left: 10px;
                  padding-right: 30px;
                  &:after {
                    right: 8px !important;
                  }
                  & > span {
                    font-size: 12px;
                    line-height: 18px;
                    color: rgba(63, 136, 197, 1);
                  }
                  &:after {
                    right: 12px;
                  }
                }
              }
              & .custom__select .select {
                padding-right: 0px;
              }
              & .wrapper__field--row {
                display: flex;
                align-items: flex-start;
                & > .wrapper__field {
                  margin-right: 20px;
                }
              }
              padding-top: 0px;
              & .wrapper__field {
                &:last-child {
                  margin-right: 0px;
                }
                &.wrapper__field--longspace {
                  margin-right: 25px;
                }
                & .select__time {
                  & button.select__label {
                    min-width: 110px;
                    min-height: 40px;
                    padding-left: 10px;
                    border: 1px solid #dadada;
                    border-radius: 3px;
                  }
                }
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 10px;
                &:last-child {
                  margin-bottom: 0px;
                }
                & > .default__pricing--input {
                  margin-right: 30px;
                  &:last-child {
                    margin-right: 0px;
                  }
                  &.long__input {
                    min-width: 110px;
                    & > input {
                      min-width: 110px;
                    }
                  }
                  &.short__input {
                    min-width: 90px;
                    & > input {
                      min-width: 90px;
                    }
                  }
                  & > input {
                    border: 1px solid #dadada;
                    border-radius: 5px;
                    min-height: 40px;
                    font-size: 14px;
                    line-height: 20px;
                    color: rgba(45, 45, 45, 1);
                    font-weight: 400;
                    padding-left: 10px;
                    padding-right: 10px;
                    max-width: 90px;
                    &::-webkit-input-placeholder {
                      /* Chrome/Opera/Safari */
                      color: rgba(45, 45, 45, 1);
                    }
                    &::-moz-placeholder {
                      /* Firefox 19+ */
                      color: rgba(45, 45, 45, 1);
                    }
                    &:-ms-input-placeholder {
                      /* IE 10+ */
                      color: rgba(45, 45, 45, 1);
                    }
                    &:-moz-placeholder {
                      /* Firefox 18- */
                      color: rgba(45, 45, 45, 1);
                    }
                  }
                  &:last-child {
                    margin-right: 0px;
                  }
                }
              }
              & .select__label {
                border-radius: 5px !important;
              }
              & .select__time {
                & .select__label {
                  padding-right: 18px;
                  & > span {
                    max-width: 80px;
                    white-space: nowrap;
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                  }
                  &:after {
                    right: 7px;
                  }
                }
              }
              & .default__unit--wrapper {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                & .words__dropdown {
                  & .custom__select {
                    & .select__dropdown {
                      margin-top: 0px;
                    }
                    & .select__label:after {
                      right: 8px;
                    }
                    & .select__label {
                      & > span {
                        font-size: 12px;
                        line-height: 18px;
                        color: rgba(63, 136, 197, 1);
                        text-transform: uppercase;
                      }
                    }
                    & .select {
                      min-width: 100px;
                      max-width: 100px;
                      min-height: 40px;
                      border: 1px solid #dadada;
                      border-radius: 5px;
                      & > button {
                        & > span {
                          width: 100%;
                          text-align: left;
                          white-space: nowrap;
                          overflow: hidden !important;
                          text-overflow: ellipsis;
                        }
                        min-width: 90px;
                        min-height: 40px;
                        padding-left: 10px;
                        padding-right: 25px;
                      }
                    }
                  }
                }
              }
              &:nth-child(2) {
                padding-left: 0px;
                padding-top: 0px;
              }
              & .unit__field {
                margin-right: 10px;
                & > input {
                  min-width: 70px;
                  max-width: 70px;
                  min-height: 40px;
                  padding-left: 10px;
                  padding-right: 10px;
                  border: 1px solid #dadada;
                  font-size: 14px;
                  line-height: 20px;
                  color: rgba(45, 45, 45, 1);
                  font-weight: 400;
                  border-radius: 5px;
                }
              }
              &:nth-child(1) {
                padding-top: 0px;
                padding-left: 45px;
                & .group__tag--checkbox {
                  & > .group__target--container {
                    min-height: 40px;
                    padding-left: 15px;
                  }
                  max-width: 170px;
                  & > .group__target--container > input {
                    max-width: 125px;
                    color: rgba(45, 45, 45, 1);
                    font-size: 12px;
                    font-weight: 600;
                    &::-webkit-input-placeholder {
                      /* Chrome/Opera/Safari */
                      color: rgba(45, 45, 45, 1);
                    }
                    ::-moz-placeholder {
                      /* Firefox 19+ */
                      color: rgba(45, 45, 45, 1);
                    }
                    :-ms-input-placeholder {
                      /* IE 10+ */
                      color: rgba(45, 45, 45, 1);
                    }
                    :-moz-placeholder {
                      /* Firefox 18- */
                      color: rgba(45, 45, 45, 1);
                    }
                  }
                }
              }
              & .source__counter {
                position: absolute;
                left: 0px;
                top: 0px;
                width: 30px;
                z-index: 2;
                background-color: rgba(227, 227, 227, 0.5);
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                height: 100%;
                height: calc(100% + 48px) !important;
                & > .remove__main {
                  position: absolute;
                  left: 0px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  right: 0px;
                  top: 12px;
                  margin-left: auto;
                  margin-right: auto;
                }
                & > span {
                  display: flex;
                  width: 100%;
                  justify-content: center;
                  align-items: center;
                  height: 100%;
                  font-size: 14px;
                  line-height: 21px;
                  color: rgba(14, 125, 125, 1);
                  font-weight: bold;
                }
              }
            }
            & th {
              padding-left: 0px;
              padding-top: 10px;
              padding-bottom: 10px;
              border-bottom: 1px solid rgba(227, 227, 227, 1);
              & h5 {
                margin: 0px;
                color: rgba(45, 45, 45, 1);
              }
            }
          }
        }
        & > h2 {
          margin-top: 0px;
          margin-bottom: 20px;
          font-size: 25px;
          line-height: 34px;
          color: rgba(14, 125, 125, 1);
          font-weight: bold;
        }
      }
    }
  }
}
.dropdown__pricing--spacer {
  margin-top: 40px !important;
  transform: none !important;
}
.price__main {
  & .table__wrapper table.table__common {
    padding-bottom: 150px;
    border-collapse: inherit;
  }
}
.price__main {
  & .group__target--container {
    background-color: #f5f5f5;

    & > .el__field--target {
      background-color: rgba(255, 255, 255, 0.8);
    }
    & > input {
      background-color: #f5f5f5;
    }
  }
  & .group__tag--checkbox .target__dropdown > ul {
    max-height: 220px;
  }
}
.modal__regular > .inner__modal--regular .price__modal--info .price__main .table__wrapper {
  border-bottom: 0px;
  &::-webkit-scrollbar {
    display: none;
  }
}
.table__other {
  padding-top: 20px;
  margin-top: -160px;
  position: relative;
  border-top: 1px solid rgba(227, 227, 227, 1);
}
.more__combination {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0px;
  margin-top: 25px;
  align-items: center;
  & > a {
    padding: 8px 20px;
    display: inline-flex;
    align-items: center;
    min-height: 40px;
    font-size: 14px;
    line-height: 21px;
    color: rgba(14, 125, 125, 1);
    font-weight: 600;
    border: 1px solid rgba(227, 227, 227, 1);
    border-radius: 3px;
    justify-content: center;
    transition: 0.4s ease all;
    &:hover {
      border-color: #0e7d7d;
    }
    & span {
      display: inline-flex;
      margin-right: 10px;
      align-items: center;
      justify-content: center;
      & img {
        width: 14px;
      }
    }
  }
}
.other__options {
  margin-top: 35px;
  & .other__option--wrapper {
    & .other__option--elem {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0px;
      }
      & > .option__inner--element {
        margin-bottom: 15px;
        max-width: 640px;
        &:last-child {
          margin-bottom: 0px;
        }
        & > .option__desc {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          & > textarea {
            max-width: 215px;
            min-width: 215px;
            height: 70px;
          }
        }
        & > .option__row {
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          & .price__select {
            & .page__select {
              & button {
                min-width: 80px;
                min-height: 40px;
                border: 1px solid #dadada;
                box-sizing: border-box;
                border-radius: 5px;
                padding-left: 10px;
                padding-right: 20px;
                &:after {
                  right: 6px;
                }
                & > span {
                  font-size: 12px;
                  line-height: 18px;
                  color: rgba(63, 136, 197, 1);
                  font-weight: 400;
                }
              }
            }
          }
          & .price__unit {
            margin-right: 10px;
            & > input {
              min-width: 125px;
              max-width: 125px;
            }
          }
          & .price__field {
            margin-right: 10px;
            & > input {
              min-width: 125px;
              max-width: 125px;
            }
          }
          & .option__name {
            margin-right: 10px;
            & > input {
              min-width: 215px;
              max-width: 215px;
            }
          }
        }
      }
      & > .option__name {
        margin-bottom: 10px;
        & > input {
          min-width: 215px;
          max-width: 215px;
        }
      }
    }
  }
  & .other__option--more {
    margin-top: 15px;
    & > a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      // min-width:175px;
      // min-height:40px;
      font-size: 14px;
      line-height: 21px;
      color: #0e7d7d;
      font-weight: 600;
      // border: 1px solid #E3E3E3;
      transition: 0.4s ease all;
      border-radius: 3px;
      &:hover {
        border-color: #0e7d7d;
      }
      & span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        & > img {
          min-width: 14px;
        }
      }
    }
  }
  & > p {
    margin-top: 0px;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 21px;
    color: rgba(45, 45, 45, 1);
    font-weight: bold;
  }
}
.different__combination {
  & > p {
    margin-top: 0px;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 22px;
    color: rgba(45, 45, 45, 1);
    font-weight: bold;
  }
  & > a {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    line-height: 21px;
    color: rgba(14, 125, 125, 1);
    font-weight: 600;
    justify-content: flex-start;
    & > span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      & > img {
        min-width: 14px;
      }
    }
  }
  & .different__combo--inputs {
    margin-bottom: 20px;
    & .elem__different--combo {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 10px;
      position: relative;
      max-width: 240px;
      & > a {
        position: absolute;
        right: -20px;
      }
      &:last-child {
        margin-bottom: 0px;
      }
      & > .different__price {
        & > input {
          min-width: 90px;
          max-width: 90px;
        }
      }
      & > .different__size {
        margin-right: 10px;
        & > input {
          min-width: 130px;
          max-width: 90px;
          min-height: 40px;
        }
      }
    }
  }
}
.price__modal--info {
  & .custom__select .select__dropdown {
    margin-top: 0px;

    & .simplebar-content-wrapper {
      height: none !important;
    }
  }
  & .simplebar-content:before,
  .simplebar-content:after {
    content: none;
  }
}
.table__other--controls {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;
  & > .table__cancel {
    margin-right: 15px;
    min-width: 125px;
    min-height: 45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #0e7d7d;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    color: rgba(14, 125, 125, 1);
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    transition: 0.4s ease all;
    &:focus {
      color: #ffffff !important;
      border-color: #0a5858;
      background-color: #0a5858;
      box-shadow: 0 0 0 0.2rem rgba(14, 125, 125, 0.5);
    }
    &:active {
      background-color: #0a5858;
      color: #fff !important;
    }
    &:hover {
      background-color: #0a5858;
      color: #fff !important;
    }
  }
  & > .table__create {
    margin-right: 15px;
    min-width: 125px;
    min-height: 45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #0e7d7d;
    border: 2px solid #0e7d7d;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    color: #fff;
    transition: 0.4s ease all;
    &:active {
      color: #ffffff;
      background-color: #094f4f;
    }
    &:focus {
      color: #ffffff;
      background-color: #0a5858;
      box-shadow: 0 0 0 0.2rem rgba(14, 125, 125, 0.5);
    }
    &:hover {
      background-color: #0a5858;
    }
  }
}
.more__variant {
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 175px;
    min-height: 40px;
    font-size: 14px;
    line-height: 21px;
    color: rgba(14, 125, 125, 1);
    font-weight: 600;
    text-decoration: none;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    transition: 0.4s ease all;
    &:hover {
      border-color: #0e7d7d;
    }
    & span {
      margin-right: 10px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      & > img {
        min-width: 14px;
      }
    }
  }
}
.modal__regular.modal__pricing {
  & > a {
    opacity: 0;
  }
  & > .inner__modal--regular {
    right: calc(-100% + 70px);
  }
}
.created__other {
  position: relative;
  max-width: 580px;
  & > a {
    position: absolute;
    top: 10px;
    right: -28px;
  }
}
.created__other--full {
  max-width: 580px;
  position: relative;
  & > .removefull__other {
    position: absolute;
    top: 9px;
    left: 229px;
    right: auto;
  }
}
.wrapper__field {
  position: relative;
  & > .remove__field--row {
    position: absolute;
    left: -30px;
  }
}
.form__pricing--wrapper {
  margin-bottom: -150px;
  width: 100%;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
.top__form--pricing {
  padding-bottom: 150px;
  min-width: 1400px;
  max-width: 100%;
  width: 100%;
  & > .top__form--head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(227, 227, 227, 1);
    position: relative;
    & h6 {
      margin: 0px;
      color: #2d2d2d;
    }
    & > .top__form--titlelong {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      & > h6 {
        white-space: nowrap;
        font-weight: 600;
        &:nth-child(1) {
          min-width: 65px;
        }
      }
    }
    & > .top__form--titlelong {
      min-width: 695px;
      width: 167%;
      & > h6 {
        width: 100%;
        &:nth-child(1) {
          width: 26%;
          min-width: 245px;
        }
        &:nth-child(2) {
          width: 11%;
        }
        &:nth-child(3) {
          width: 60%;
        }
      }
    }
    & > .top__form--title {
      &:nth-child(1) {
        min-width: 230px;
        width: 41%;
      }
      &:nth-child(2) {
        min-width: 195px;
        width: 34%;
      }
    }
  }
}
.price__main {
  & .more__size {
    padding-top: 20px;
    margin-top: 10px;
    border-top: 1px solid rgba(227, 227, 227, 1);
    & > a {
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      line-height: 21px;
      color: #0e7d7d;
      font-weight: 600;
      justify-content: flex-start;
      & span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        & > img {
          min-width: 14px;
        }
      }
    }
  }
  & .price__select {
    &.field__custom {
      & .select {
        & > button.select__label {
          border: 1px solid #c5c5c5 !important;
        }
      }
    }
  }
  & .field__custom {
    margin-bottom: 0px;
    & > textarea {
      border: 1px solid #c5c5c5;
    }
    & > input {
      border: 1px solid #c5c5c5;
    }
  }
  & .field__custom {
    & .custom__select {
      &.page__select button {
        background-color: #f5f5f5 !important;
        border: 0px !important;
      }
    }
  }
}
.content__form--container {
  padding-top: 10px;
  padding-bottom: 30px;
  & .elem__form--pricing {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    & > .column__form--pricing {
      position: relative;
      min-width: 230px;
      width: 41%;
      padding-left: 0px;
      padding-top: 30px;
      & .column__combination {
        padding-left: 45px;
        & > .group__tag--checkbox {
          width: 100%;
          max-width: 170px;
          & input {
            width: 100%;
            color: #2d2d2d;
            font-size: 12px;
            font-weight: 600;
          }
        }
      }
      & > span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0px;
        top: 0px;
        height: 100%;
        width: 30px;
        background-color: rgba(227, 227, 227, 0.5);
        font-size: 14px;
        line-height: 21px;
        color: rgba(14, 125, 125, 1);
        font-weight: bold;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
.size__prize--group {
  min-width: 695px;
  width: 167%;
  padding-top: 30px;
  & .elem__size--prize--group {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0px;
    }
    & .wrapper__field {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      & > .field__custom {
        &:last-child {
          margin-right: 0px;
        }
      }
      & > .field__custom.short__input {
        min-width: 90px;
        max-width: 90px;
      }
      & > .field__custom.long__input {
        margin-right: 10px;
        &:last-child {
          margin-right: 0px;
        }
        min-width: 125px;
        max-width: 125px;
      }
    }
    & .wrapper__field--row {
      display: flex;
      justify-content: flex-start;
      width: 60%;
      & .wrapper__field {
        margin-right: 20px;
        & .custom__select {
          &.fee__select {
            & .select__label {
              min-width: 85px;
              max-width: 85px;
              padding-left: 10px;
              padding-right: 25px;
              background-color: #f5f5f5;
              & > span {
                font-size: 12px;
                line-height: 18px;
                color: #3f88c5;
              }
            }
          }
          & .select__label {
            min-width: 125px;
            max-width: 125px;
            min-height: 40px;
            padding-left: 10px;
            padding-right: 25px;
            background-color: #f5f5f5;
            border: 1px solid #c5c5c5;
            &:after {
              right: 6px;
            }
          }
        }
        &:last-child {
          margin-right: 0px;
        }
        & > .field__custom.short__input {
          min-width: 90px;
          max-width: 90px;
        }
        & > .field__custom.long__input {
          margin-right: 10px;
          &:last-child {
            margin-right: 0px;
          }
          min-width: 125px;
          max-width: 125px;
        }
      }
    }
    & > .wrapper__field {
      &:nth-child(1) {
        width: 26%;
        min-width: 245px;
      }
      &:nth-child(2) {
        width: 11%;
        min-width: 90px;
      }
    }
  }
}
.column__units {
  display: flex;
  align-items: flex-start;
  padding-top: 30px;
  justify-content: flex-start;
  min-width: 195px;
  width: 34%;
  & .field__custom {
    width: auto;
    & > input {
      border: 1px solid #c5c5c5;
    }
  }
  & > .matt__group {
    & > .custom__select {
      & button.select__label {
        min-width: 100px;
        max-width: 100px;
        min-height: 40px;
        padding-left: 10px;
        padding-right: 25px;
        background-color: #f5f5f5;
        border: 1px solid #c5c5c5;
        &:after {
          right: 7px;
        }
        & > span {
          width: 100%;
          text-align: left;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
          font-size: 12px;
          line-height: 18px;
          color: #3f88c5;
          text-transform: uppercase;
        }
      }
    }
  }
  & > .unit__group {
    margin-right: 10px;
    & > input {
      min-width: 70px;
      max-width: 70px;
    }
  }
}
.select__once--field {
  width: 100%;
  position: relative;
  & > input {
    cursor: pointer;
  }
  & .select__main--dropdown {
    position: absolute;
    top: 56px;
    left: 0px;
    width: 100%;
    padding: 10px;
    background: #ffffff;
    box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    z-index: 3;
    & .search__select--dropdown {
      max-height: 360px;
      overflow-y: auto;
      padding-right: 5px;
      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-track {
        background-color: #e9e9e9;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #aeaeae;
      }
      & > ul {
        padding: 0px;
        list-style-type: none;
        margin: 0px;
        & > li {
          display: flex;
          width: 100%;
          margin-bottom: 1px;
          &.active__select--dropdown {
            & > a {
              background: url('../images/whitecheck.svg') !important;
              background-repeat: no-repeat !important;
              background-size: auto !important;
              background-position: 95% center !important;
              background-color: #0e7d7d !important;
              border-radius: 4px !important;
              color: #fff !important;
            }
          }
          & > a {
            display: flex;
            width: 100%;
            height: 35px;
            padding: 5px 10px;
            justify-content: flex-start;
            align-items: center;
            font-size: 13px;
            line-height: 21px;
            font-weight: 600;
            color: #2d2d2d;
            text-decoration: none;
            &:hover {
              background: #ebf1f1;
              border-radius: 4px;
              color: #0e7d7d;
            }
          }
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
    & .search__select--field {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      height: 40px;
      margin-bottom: 10px;
      & > input {
        width: 100%;
        height: 40px;
        padding-left: 10px;
        padding-right: 10px;
        color: #000;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        outline: none;
        border: 1px solid #dddddd;
        border-radius: 3px;
      }
    }
  }
  &:before {
    content: '';
    position: absolute;
    right: 10px;
    width: 12px;
    height: 15px;
    background: url('../images/servicearrow.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    top: 50%;
    -webkit-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
  }
  & > input {
    width: 100%;
    height: 36px;
    background: #ffffff;
    border-radius: 5px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 12px;
    color: #444444;
    font-weight: 600;
    outline: none;
    border: 0px;
  }
}
.select__two {
  & .select__multi--field {
    position: relative;
    & .multi__dropdown {
      position: absolute;
      left: 0px;
      width: 100%;
      top: 56px;
      padding: 10px;
      background: #ffffff;
      box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
      border-radius: 3px;
      z-index: 3;
    }
    & .multi__dropdown {
      & .multi__list {
        max-height: 360px;
        overflow-y: auto;
        padding-right: 5px;
        &::-webkit-scrollbar {
          width: 3px;
        }

        &::-webkit-scrollbar-track {
          background-color: #e9e9e9;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #aeaeae;
        }
        & > ul {
          padding: 0px;
          list-style-type: none;
          margin: 0px;
          width: 100%;
          & > li {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            &.multi__active {
              & > a {
                border-radius: 4px;
                background: url('../images/whitecheck.svg') !important;
                background-repeat: no-repeat !important;
                background-size: auto !important;
                background-position: 95% center !important;
                background-color: #0e7d7d !important;
                color: #fff;
              }
            }
            & > a {
              display: flex;
              width: 100%;
              min-height: 35px;
              border-radius: 4px;
              align-items: center;
              justify-content: flex-start;
              padding-left: 10px;
              padding-right: 10px;
              font-size: 13px;
              line-height: 21px;
              font-weight: 600;
              color: #2d2d2d;
              &:hover {
                background: #ebf1f1;
              }
            }
            &:last-child {
              margin-bottom: 0px;
            }
            margin-bottom: 1px;
          }
        }
      }
      & .multi__submit {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 12px;
        & > a {
          display: inline-flex;
          width: 100%;
          color: #0e7d7d;
          font-size: 13px;
          line-height: 21px;
          font-weight: 600;
          text-decoration: none;
          min-height: 35px;
          align-items: center;
          justify-content: center;
          background: #ffffff;
          border: 1px solid #0e7d7d;
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
          border-radius: 4px;
        }
      }
      & .multi__search {
        width: 100%;
        margin-bottom: 10px;
        & > input[type='text'] {
          width: 100%;
          height: 40px;
          background: #ffffff;
          border: 1px solid #dddddd;
          border-radius: 3px;
          padding-left: 10px;
          padding-right: 10px;
          font-size: 14px;
          line-height: 24px;
          font-weight: 400;
          color: #000;
        }
      }
    }
    & .multi__container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      min-height: 36px;
      background: #ffffff;
      border-radius: 5px;
      padding: 3px;
      flex-wrap: wrap;
      position: relative;
      padding-right: 30px;
      cursor: pointer;
      & > p {
        font-size: 12px;
        color: #444444;
        font-weight: 600;
        opacity: 0.8;
        padding-left: 15px;
      }
      &:before {
        content: '';
        position: absolute;
        right: 10px;
        width: 12px;
        height: 15px;
        background: url('../images/servicearrow.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        top: 50%;
        -webkit-transform: translate(0%, -50%);
        transform: translate(0%, -50%);
      }
      & > .multi__more {
        padding: 10px;
        background-color: #0e7d7d;
        border-radius: 3px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 33px;
        min-height: 30px;
        & > p {
          margin: 0px;
          font-size: 16px;
          color: #fff;
          font-weight: 400;
          line-height: 0px;
          position: absolute;
          margin-top: -8px;
        }
      }
      & > .multi__elem {
        margin-right: 3px;
        padding: 8px 10px;
        background: #0e7d7d;
        border-radius: 3px;
        display: flex;
        align-items: center;
        min-height: 30px;
        justify-content: flex-start;
        margin-bottom: 3px;
        position: relative;
        top: 1px;
        & p {
          margin: 0px;

          font-size: 12px;
          line-height: 12px;
          color: #ffffff;
          font-weight: 600;
        }
        & a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-left: 10px;
        }
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}
@media (max-width: 1740px) {
  .checkout__progress--section {
    & .element__progress {
      padding-right: 15px;
      & span {
        position: static;
        margin-right: 12px;
      }
    }
  }
}
@media (max-width: 1400px) {
  .simplified__block {
    & .checkout__progress--section {
      grid-template-columns: repeat(5, 1fr);
    }
  }
  .checkout__progress--section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 10px;
    & .element__progress {
      & span {
        left: 25px;
        position: absolute;
      }
    }
  }
}
@media (max-width: 1200px) {
  .simplified__block {
    & .pair__picker .pair__container > .head__pair > .pair__double {
      flex-direction: column;
      & > .pair__spacer {
        margin: 15px 0px;
        transform: rotate(90deg);
      }
    }
    .checkout__progress--section {
      grid-template-columns: repeat(2, 1fr);

      display: flex;
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      & .element__progress {
        width: 100% !important;
        max-width: 100% !important;
        &.active__progress {
          padding-left: 20px;
          padding-right: 20px;
          max-width: 100%;
          & > p {
            display: block;
          }
          & span {
            position: static;
            margin-left: 0px;
            margin-right: 10px;
          }
        }
        & span {
          left: 0px;
          right: 0px;
          margin-left: auto;
          margin-right: auto;
        }
        & > p {
          display: none;
        }
      }
    }
  }
  .translation__wrapper--picker .translation__picker--element .picker__choose--grid {
    flex-direction: column;
    & > .elem__choose--grid {
      border-radius: 0px !important;
      margin-right: 0px !important;
      margin-bottom: -2px !important;
      &:last-child {
        margin-bottom: 0px !important;
      }
    }
  }
  .pair__picker {
    & .pair__container {
      & > .head__pair {
        & > .pair__double {
          & .pair__elem {
            max-width: 180px;
          }
        }
      }
    }
  }
  .guest__payment--method {
    & > .chosen__element {
      flex-direction: column;
      & > .chosen__info--left {
        max-width: 100%;
        width: 100%;
      }
      & > .chosen__info--help {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        & > h2 {
          margin-top: 12px;
          margin-left: 0px;
        }
      }
      & > .spacer {
        margin: 12px 0px;
        width: 100%;
        height: 1px;
      }
    }
    & > .timeline__block {
      & > .choose__timeline--grid {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        & > .elem__choose--timeline {
          margin-right: 0px;
          margin-bottom: 15px;
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  .checkout__long {
    & .content__guest {
      & > .guest__content--main {
        & .guest__checkout {
          & .guest__head {
            margin-bottom: 25px;
          }
        }
      }
    }
  }
  .complex__content {
    & .help__block {
      & > h2 {
        font-size: 22px;
        line-height: 31px;
      }
    }
  }
}
.elem__choose--timeline {
  & .datepicker {
    margin-right: 10px;
    &:after {
    }
    & .input {
      max-width: 165px;
      padding: 5px 8px;
      border: 0px;
    }
  }
}
@media (max-width: 991px) {
  .menu__permissions {
    & .permissions-control-dropdown__box {
      left: 50%;
      transform: translate(-50%, 0);
      right: auto;
    }
  }
  .simplified__block {
    & .checkout__progress--section {
      padding-top: 60px;
      position: relative;
      & .element__progress--wrapper {
        min-height: 50px;
        clip-path: polygon(
          0 0,
          calc(100% - 15px) 0%,
          100% 50%,
          calc(100% - 15px) 100%,
          0 99%,
          15px 50%
        ) !important;
        & > .element__progress {
          clip-path: polygon(
            0 0,
            calc(100% - 15px) 0%,
            100% 50%,
            calc(100% - 15px) 100%,
            0 99%,
            15px 50%
          ) !important;
          min-height: 48px;
          position: relative !important;
        }
      }
      & .active__progress {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        width: 100%;
      }
    }
  }
  header {
    &.simplified__header {
      & .outer__header {
        padding-left: 0px;
        padding-right: 0px;
        & .header__info {
          max-width: 420px;
          text-align: right;
          & > p {
          }
        }
      }
    }
  }
  .pair__picker .pair__container > .head__pair > .pair__double .pair__elem {
    max-width: 240px;
  }
  .checkout__long {
    & .content__guest {
      flex-direction: column;
      & > .guest__content--main {
        min-height: 0px;
      }
      & > .guest__content--side {
        width: 100%;
        max-width: 100%;
        margin-left: 0px;
        margin-top: 25px;
      }
    }
  }
  .checkout__long {
    width: calc(100% - 30px);
  }
  .checkout__progress--section {
    grid-template-columns: repeat(2, 1fr);
  }
  .complex__content {
    padding: 25px 15px;
    width: calc(100% - 30px);
    & .help__block {
      & > h2 {
        font-size: 19px;
        line-height: 28px;
      }
    }
  }
  .checkout__progress--section {
    display: flex;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    & .element__progress {
      min-width: 90px;
      max-width: 90px;
      &.active__progress {
        padding-left: 20px;
        padding-right: 20px;
        max-width: 100%;
        & > p {
          display: block;
        }
        & span {
          position: static;
          margin-left: 0px;
          margin-right: 10px;
        }
      }
      & span {
        left: 0px;
        right: 0px;
        margin-left: auto;
        margin-right: auto;
      }
      & > p {
        display: none;
      }
    }
  }
}
.additional__float .uncheck__document > p {
  min-width: 15px;
}
.info__tooltip > img {
  min-width: 15px;
}
.additional__float .additional__copies > p {
  max-width: 85%;
}
.uncheck__file p span img {
  min-width: 28px;
}
@media (max-width: 767px) {
  .created__other--full > .removefull__other {
    right: -28px;
    left: auto;
  }
  .created__other--full {
    max-width: 100%;
  }
  .created__other {
    max-width: 100%;
  }
  .different__combination .different__combo--inputs .elem__different--combo {
    max-width: 100%;
    & > a {
      top: 50%;
      right: -25px;
      transform: translate(0%, -50%);
    }
  }
  .other__options .other__option--wrapper .other__option--elem {
    & > .option__inner--element {
      & > .option__desc {
        & > textarea {
          min-width: 0px;
          width: 100%;
          max-width: 100%;
        }
      }
      & > .option__row {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & > .price__select {
          width: 100%;
          max-width: 100%;
          min-width: 0px;

          & .custom__select .select {
            padding-right: 0px;
          }
        }
        & > .price__unit {
          margin-right: 0px;
          min-width: 0px;
          max-width: 100%;
          width: 100%;
          margin-bottom: 10px;
          & > input {
            min-width: 0px;
            max-width: 100%;
            width: 100%;
          }
        }
        & > .price__field {
          margin-right: 0px;
          min-width: 0px;
          max-width: 100%;
          width: 100%;
          margin-bottom: 10px;
          & > input {
            min-width: 0px;
            max-width: 100%;
            width: 100%;
          }
        }
        & > .option__name {
          margin-right: 0px;
          max-width: 100%;
          width: 100%;
          margin-bottom: 10px;
          & > input {
            width: 100%;
            min-width: 0px;
            max-width: 100%;
          }
        }
      }
    }
    & > .option__name {
      & > input {
        max-width: 100%;
        min-width: 0px;
        width: 100%;
      }
    }
  }
  .different__combination .different__combo--inputs .elem__different--combo {
    flex-direction: column;
    & > .different__price {
      width: 100%;
      & > input {
        width: 100%;
        max-width: 100%;
      }
    }
    & > .different__size {
      margin-right: 0px;
      margin-bottom: 10px;
      width: 100%;
      & > input {
        max-width: 100%;
        width: 100%;
      }
    }
  }
  .simplified__block {
    & .pair__picker .pair__container > .head__pair {
      padding-top: 25px !important;
    }
  }
  .order__confirm {
    padding-top: 15px;
    padding-bottom: 25px;
    min-height: 0px;
  }
  .order__confirm > .order__info > .order__details > span {
    margin-bottom: 25px;
  }
  .order__confirm > .order__info > h2 {
    font-size: 22px;
    line-height: 28px;
  }
  .guest__content--main .payment__method > h2 {
    margin-bottom: 15px;
  }
  .shipping__information--section .shipping__form {
    max-width: 100%;
  }
  .shipping__information--section .shipping__form form > .phone__group {
    flex-direction: column;
    & > .field__custom {
      width: 100% !important;
      max-width: 100% !important;
      margin-bottom: 10px;
      margin-right: 0px !important;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .shipping__information--section .shipping__form form > .city__group {
    flex-direction: column;
    & > .field__custom {
      width: 100% !important;
      max-width: 100% !important;
      margin-bottom: 10px;
      margin-right: 0px !important;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .shipping__information--section .shipping__form form > .adress__group {
    flex-direction: column;
    & > .field__custom {
      width: 100% !important;
      max-width: 100% !important;
      margin-bottom: 10px;
      margin-right: 0px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .simplified__block .checkout__progress--section .element__progress {
    min-width: 70px;
    max-width: 70px;
  }
  .simplified__block .translation__correct--wrapper .translation__correct--field {
    max-width: 100%;
  }
  .simplified__block {
    & .get__started {
      & .head__started {
        margin-bottom: 15px;
      }
      & .started__form {
        max-width: 100%;
        margin-bottom: 15px;
      }
      & .started__note {
        max-width: 100%;
      }
    }
  }
  header {
    &.simplified__header {
      & .outer__header {
        padding-left: 0px;
        padding-right: 0px;
        & .header__logo {
          & > a {
            & > img {
              max-width: 200px;
            }
          }
        }
        & .header__info {
          max-width: 420px;
          text-align: right;
          & > p {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }
  .modal__regular.modal__additional--service > .inner__modal--regular {
    padding: 30px 25px;
  }
  .additional__float .upload-content__files {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & .upload-content__files-list {
      margin-bottom: 20px;
    }
  }
  .additional__copies--container .copies__container--wrapper > .element__copy .uncheck__file {
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .additional__copies--container .copies__container--wrapper > .element__copy {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .modal__regular.modal__additional--service > .inner__modal--regular {
    max-width: 420px;
  }
  .modal__regular.modal__additional--service > a {
    right: 435px;
  }
  .checkout__progress--section .element__progress {
    min-height: 45px;
    min-width: 70px;
    max-width: 70px;
  }
  .additional__float .float__controls {
    margin-top: 25px;
  }
  .additional__float > .float__inner .float__switcher > p {
    max-width: 90%;
  }
  .additional__float > .float__inner .float__switcher > p img {
    min-width: 15px;
  }
  .additional__float .additional__copies > p {
    max-width: 90%;
    & img {
      min-width: 15px;
    }
  }
  .info__tooltip--block > p {
    font-size: 11px;
  }

  .additional__float > .float__inner > .cost__info > p {
    font-size: 12px;
  }
  .additional__float > .float__inner > .cost__info {
    padding: 10px;
  }
  .additional__float .uncheck__document > p,
  .additional__float > .float__inner > .document__certif--title > p {
    max-width: 90%;
  }
  .additional__float > .float__inner > .document__certif--title p img {
    min-width: 15px;
  }
  .additional__float .uncheck__document > p span img {
    min-width: 15px;
  }
  .additional__guest {
    & > h2 {
      margin-bottom: 15px;
    }
  }
  .additional__guest {
    & .additional__list {
      & .additional__element {
        max-width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & > .additional__left {
          margin-bottom: 15px;
        }
        & > .additional__right {
          margin-left: 0px;
        }
      }
    }
  }
  .brief__wrapper {
    & .improve__field {
      max-width: 100%;
      & .content__files {
        display: flex;
        flex-direction: column;
        align-items: center;
        & .upload-content__drag-drop.upload-content__drag-drop__old.upload__block {
          max-width: 100%;
        }
      }
    }
  }
  .guest__payment--method {
    & > h2 {
      margin-bottom: 15px;
    }
  }
  .guest__content--main {
    & .payment__method {
      max-width: 100%;
    }
  }
  .checkout__long {
    & .content__guest {
      & > .guest__content--main {
        & .lang__pair {
          & > .head__pair {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
  .pair__picker {
    & .pair__container {
      & > .head__pair {
        flex-direction: column-reverse;
        position: relative;
        padding-top: 40px;
        & > .pair__remove {
          position: absolute;
          top: 10px;
          right: 20px;
        }
        & > .pair__double {
          & .pair__elem {
            max-width: 100%;
          }
        }
      }
    }
  }
  .checkout__long {
    & .content__guest {
      & .guest__content--main {
        & .signed__form {
          & .element__signed--controls {
            max-width: 100%;
          }
        }
        & .sign__form {
          max-width: 100%;

          & .field__submit {
            max-width: 100%;
          }
          & .button__guest {
            max-width: 100%;
          }
        }
      }
      & > .guest__content--side {
        min-width: 0px;
        & .guest__security {
          padding: 20px 15px;
        }
        & > .guest__summary {
          padding: 20px 15px;
        }
      }
      & > .guest__content--main {
        padding: 25px 15px;
        & .guest__checkout {
          & .guest__private {
            max-width: 100%;
            width: 100%;
          }
          & .guest__business {
            max-width: 100%;
            width: 100%;
          }
          & .guest__switcher {
            max-width: 100%;
            width: 100%;
          }
        }
      }
    }
  }
  .checkout__progress--section {
    grid-template-columns: 1fr;
    grid-row-gap: 5px;
    & .element__progress {
      height: 35px;
      & > span {
        min-width: 24px;
        max-width: 24px;
        min-height: 24px;
        max-height: 24px;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  .complex__content {
    & .help__block {
      & .help__links {
        & > .elem__help {
          & > a {
            max-width: 100%;
          }
        }
      }
    }
  }
  .info__tooltip--block {
    min-width: 145px;
    max-width: 145px;
  }
}
@media (max-width: 640px) {
  .simplified__block .checkout__progress--section .element__progress {
    max-width: 100%;
  }
  header {
    &.simplified__header {
      & .outer__header {
        & .header__logo {
          & > a {
            & > img {
              max-width: 170px;
            }
          }
        }
        & .header__info {
          margin-left: 10px;
          max-width: 320px;
        }
      }
    }
  }
  .checkout__progress--section {
    padding-top: 55px;
    position: relative;
    & .element__progress {
      min-width: 0px;
      max-width: 100%;
      width: 100%;
    }
    & .element__progress.active__progress {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
    }
  }
  .thank__guest {
    & .thank__final--download {
      & > a {
        font-size: 13px;
        max-width: 320px;
      }
    }
    & .thank__review {
      margin-bottom: 20px;
      & > p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
    padding-top: 35px;
    padding-bottom: 45px;
    & .thank__controls {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 320px;
      margin-left: auto;
      margin-right: auto;
      & > a {
        font-size: 13px;
        line-height: 18px;
        & > span {
          margin-right: 8px;
        }
        &:nth-child(1) {
          margin-right: 0px;
          margin-bottom: 10px;
        }
      }
    }
    & .thank__faq {
      & > h6 {
        font-size: 20px;
        line-height: 28px;
      }
      & > .thank__faq--elem {
        & > .thank__faq--title {
          padding-left: 15px;
        }
        & > .thank__faq--content {
          padding: 15px;
          padding-top: 0px;
          & > p {
            font-size: 15px;
            line-height: 23px;
          }
        }
      }
      & .thank__faq--head {
        & > p {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
    & .thank__info {
      & > h2 {
        font-size: 22px;
        line-height: 30px;
      }
      & > p {
        font-size: 16px;
        line-height: 22px;
      }
    }
    & .thank__image {
      & > img {
        max-width: 95px;
      }
    }
  }
}
@media (max-width: 576px) {
  .order__confirm > .order__info > .order__details > .order__email--wrapper > .order__email {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > p {
      margin-right: 0px;
      margin-bottom: 5px;
    }
  }

  .translation__wrapper--picker
    .translation__picker--element
    .picker__choose--grid
    > .elem__choose--grid {
    padding: 10px;
    padding-bottom: 15px;
  }
  .additional__float .uncheck__document .uncheck__list .elem__uncheck .uncheck__check {
    margin-right: 5px;
  }
  .pair__picker {
    & .pair__container {
      & > .head__pair {
        & > .pair__double {
          flex-direction: column;
          & .pair__spacer {
            margin: 15px 0px;
            transform: rotate(90deg);
          }
        }
      }
    }
  }
  .profile__header {
    &.guest__profile {
      & .user-dropdown__toggle-name {
        display: inline-flex;
      }
    }
  }
}
@media (max-width: 480px) {
  .select__two .select__multi--field .multi__container > .multi__elem {
    &:nth-child(2) {
      display: none;
    }
  }
  .created__other--full {
    padding-right: 20px;
    & .created__other > a {
      right: -24px !important;
    }
    & .created__other {
      padding-right: 0px !important;
    }
  }
  .created__other--full > .removefull__other {
    right: -4px;
  }
  .created__other > a {
    right: -4px;
  }
  .created__other {
    padding-right: 20px;
  }
  .different__combination .different__combo--inputs .elem__different--combo.combo__created {
    padding-right: 20px;
    & > a {
      right: -4px;
    }
  }
  .more__variant {
    margin-top: 15px;
  }
  .modal__regular.modal__pricing > .inner__modal--regular {
    padding: 30px 15px;
  }
  .table__other--controls {
    margin-top: 25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > .table__cancel {
      margin-right: 0px;
      min-width: 0px;
      width: 100%;
      max-width: 100%;
    }
    & > .table__create {
      min-width: 0px;
      width: 100%;
      max-width: 100%;
      margin-right: 0px;
      margin-bottom: 10px;
    }
  }
  .order__confirm > .order__info > .order__details > .order__email--wrapper > .order__email > p {
    font-size: 15px;
    line-height: 23px;
  }
  .order__confirm > .order__info > h2 {
    font-size: 19px;
    line-height: 25px;
  }
  header {
    &.simplified__header {
      & .outer__header {
        flex-direction: column;
        & .header__info {
          margin-left: 0px;
          margin-top: 7px;
          text-align: center;
        }
      }
    }
  }
  .modal__regular.modal__additional--service > .inner__modal--regular {
    max-width: 290px;
    padding: 30px 15px;
  }
  .modal__regular.modal__additional--service > a {
    right: 300px;
  }
  .additional__float .float__controls {
    flex-direction: column;
    width: 100%;
    max-width: 100%;
  }
  .additional__float .float__controls > .float__add {
    margin-right: 0px;
    min-width: 0px;
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }
  .additional__float .float__controls > .float__cancel {
    min-width: 0px;
    width: 100%;
    max-width: 100%;
  }
  .additional__guest .additional__list .additional__element > .additional__left {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    & > .additional__left--image {
      margin-right: 0px;
      margin-bottom: 10px;
    }
  }
  .additional__guest .additional__list .additional__element > .additional__right {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > .additional__controls {
      margin-left: 0px;
    }
    & > .price__additional {
      margin-bottom: 10px;
    }
  }
  .additional__complex--controls {
    & > .complex__controls--inner {
      flex-wrap: wrap;
      max-width: 240px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .guest__content--main {
    & .payment__method {
      & .payment__method--grid {
        & > .elem__payment--method {
          padding: 15px 12px;
          & > p {
            & > span {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
  .checkout__long {
    & .content__guest {
      & > .guest__content--main {
        & .guest__checkout {
          & .guest__switcher {
            & ul {
              flex-direction: column;
              justify-content: center;
              align-items: center;
              & > li {
                margin-right: 0px;
                margin-bottom: 3px;
                &:last-child {
                  margin-bottom: 0px;
                }
              }
            }
          }
        }
      }
    }
  }
  .complex__content {
    & .help__block {
      & .help__links {
        & > .elem__help {
          & .help__info {
            & > h6 {
              font-size: 15px;
              line-height: 19px;
            }
          }
          & > a {
            padding-right: 15px;
            flex-direction: column;
            & > .help__svg {
              margin-left: 0px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 360px) {
  .modal__regular.modal__additional--service > .inner__modal--regular {
    max-width: 260px;
    padding: 30px 15px;
  }
  .modal__regular.modal__additional--service > a {
    right: 270px;
  }
}

.help__box {
  width: 100%;
  padding: 24px;
  min-height: calc(100vh - 100px);
  background-color: #fff;
  box-shadow: 0px 4px 10px 0px rgba(138, 138, 138, 0.2);
  border-radius: 8px;
}
.help__box .list__help {
  max-width: 600px;
  width: 100%;
}
.help__box .list__help > .elem__help {
  width: 100%;
  margin-bottom: 10px;
}
.help__box .list__help > .elem__help .help__arrow {
  min-width: 46px;
  margin-left: 45px;
  position: absolute;
  right: 15px;
  top: 32px;
}
.help__box .list__help > .elem__help .help__arrow > img {
  opacity: 0.15;
  -webkit-transition: 0.4s ease all;
  transition: 0.4s ease all;
}
.help__box .list__help > .elem__help .help__info > h6 {
  margin-top: 0px;
  margin-bottom: 3px;
  font-size: 16px;
  line-height: 21px;
  font-weight: bold;
  color: #0e7d7d;
}
.help__box .list__help > .elem__help .help__info > p {
  margin: 0px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #3b3b3b;
}
.help__box .list__help > .elem__help > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%;
  max-width: 540px;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  -webkit-transition: 0.4s ease all;
  transition: 0.4s ease all;
  padding: 15px;
  padding-right: 75px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  position: relative;
}
.help__box .list__help > .elem__help > a .help__svg {
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  margin-right: 10px;
  max-height: 50px;
  background: #e7f2f2;
  border-radius: 100px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-transition: 0.4s ease all;
  transition: 0.4s ease all;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.help__box .list__help > .elem__help > a .help__svg path {
  transition: 0.4s ease all;
}
.help__box .list__help > .elem__help > a:hover {
  border-color: #0e7d7d;
}
.help__box .list__help > .elem__help > a:hover .help__arrow > img {
  opacity: 1;
}
.help__box .list__help > .elem__help > a:hover .help__svg {
  background-color: #0e7d7d;
}
.help__box .list__help > .elem__help > a:hover .help__svg path {
  fill: #fff;
}
.help__box .list__help > .elem__help:last-child {
  margin-bottom: 0px;
}
.help__box > h6 {
  margin-top: 0px;
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  color: #0e7d7d;
}

.projects__table {
  border-radius: 8px;
  background-color: #fff;
  padding-bottom: 20px;
  box-shadow: 0px 4px 10px 0px rgba(138, 138, 138, 0.2);
  min-height: calc(100vh - 200px);
}
.projects__table .controls__project {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.projects__table .controls__project > a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  max-height: 32px;
  border-radius: 4px;
  border: 1px solid #dadada;
  margin-right: 8px;
  transition: 0.3s ease all;
}
.projects__table .controls__project > a:hover {
  border-color: #0e7d7d;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}
.projects__table .controls__project > a:last-child {
  margin-right: 0px;
}
.projects__table table.table__common .subproduct {
  text-decoration: none;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: #188ef0;
}
.projects__table table.table__common .icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  border-radius: 250px;
  max-height: 32px;
}
.projects__table table.table__common .icon.light {
  background-color: #f0f7f7;
}
.projects__table table.table__common .icon.dark {
  background-color: #0e7d7d;
}
.projects__table table.table__common .icon img {
  max-width: 24px;
  max-height: 24px;
}
.projects__table table.table__common .number {
  text-decoration: underline;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: #188ef0;
}
.projects__table table.table__common th:nth-child(1) {
  padding-left: 24px;
}
.projects__table table.table__common td:nth-child(1) {
  padding-left: 24px;
}
.projects__table table.table__common th:last-child {
  padding-right: 24px;
}
.projects__table table.table__common td:last-child {
  padding-right: 24px;
}
.projects__table table.table__common tr td {
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #dadada;
}
.projects__table table.table__common tr {
  background-color: transparent !important;
}
.projects__table table.table__common th {
  border-bottom: 1px solid #dadada;
  padding-top: 8px;
  padding-bottom: 8px;
}
.projects__table table.table__common th h5 {
  color: #7e7e7e;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
}

.heading__ {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.heading__ .left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.heading__ .left > h6 {
  margin: 0px;
  font-size: 20px;
  line-height: 32px;
  font-weight: bold;
  color: #0e7d7d;
}
.heading__ .left > a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  border-radius: 6px;
  border: 1px solid #0e7d7d;
  background-color: #fff;
  margin-right: 24px;
  transition: 0.3s ease all;
}
.heading__ .left > a:hover {
  box-shadow: inset 0 0 0 1.2px #0e7d7d;
}
.heading__ .left > a:active {
  box-shadow: inset 0 0 0 1.5px rgba(14, 125, 125, 0.3);
}
.heading__ .btn.btn-white {
  min-height: 40px;
  padding-left: 12px;
  padding-right: 12px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #0e7d7d;
  border-radius: 6px;
  background-color: #fff;
  font-size: 14px;
  line-height: 18px;
  color: #0e7d7d;
  font-weight: 600;
  box-shadow: 0px 4px 10px 0px rgba(138, 138, 138, 0.2);
  transition: 0.3s ease all;
  box-shadow: none;
}
.heading__ .btn.btn-white:hover {
  box-shadow: inset 0 0 0 1.2px #0e7d7d;
}
.heading__ .btn.btn-white:active {
  box-shadow: inset 0 0 0 1.5px rgba(14, 125, 125, 0.3);
}
.heading__ .btn.btn-white span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
.heading__ .right {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 15px;
}

.edit__product--popup {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 120;
  background-color: rgba(0, 0, 0, 0.5);
}
.edit__product--popup .upload__block.upload-content__files {
  padding: 0px;
}
.edit__product--popup .upload-content__files .documents__files .head__documents {
  flex-direction: column;
  align-items: center;
}
.edit__product--popup .upload-content__files.upload__block .documents__files {
  margin-top: 0px;
  width: 100%;
  /*			overflow-x:auto;*/
  /* Track */
  /* Handle */
}
.edit__product--popup .upload-content__files.upload__block .documents__files::-webkit-scrollbar {
  width: 3px;
  border-radius: 3px;
  height: 3px;
}
.edit__product--popup
  .upload-content__files.upload__block
  .documents__files::-webkit-scrollbar-track {
  background: #e9e9e9;
}
.edit__product--popup
  .upload-content__files.upload__block
  .documents__files::-webkit-scrollbar-thumb {
  background: #aeaeae;
  border-radius: 3px;
}
.edit__product--popup .upload-content__files.upload__block .documents__files .docs__list {
  overflow-x: auto;
  max-height: 140px;
  overflow-y: auto;
}
.edit__product--popup .upload-content__files.upload__block .documents__files > div {
  /*				min-width: 540px;*/
}
.edit__product--popup .documents__files {
  /*overflow-x:auto;
    padding-right: 0px;
    margin-top: 0px!important;
    &>div{
    	min-width: 540px;
    }*/
}

.guest__content--main .help__box {
  padding: 0px;
  box-shadow: none;
  & .list__help > .elem__help > a {
    align-items: center;
    position: relative;
    padding-right: 48px;
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      min-width: 24px;
      max-width: 24px;
      min-height: 24px;
      max-height: 24px;
      right: 12px;
      background: url('../images/chgreen.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      opacity: 0;
      transition: 0.3s ease all;
    }
    &.picked {
      background-color: #e7f2f2;
      border-color: rgba(14, 125, 125, 0.1);
      &:before {
        opacity: 1;
      }
      & .help__svg {
        background-color: #ffffff;
        & svg path {
          fill: rgb(14, 125, 125);
        }
      }
    }
    &:hover {
      background-color: #e7f2f2;
      border-color: rgba(14, 125, 125, 0.1);
      & .help__svg {
        background-color: #ffffff;
        & svg path {
          fill: rgb(14, 125, 125);
        }
      }
    }
    & .help__info > h6 {
      color: #292929;
      font-size: 14px;
      line-height: 140%;
      font-weight: bold;
    }
    & .help__info {
      & > p {
        color: #7e7e7e;
      }
    }
  }
  & > .top {
    margin-bottom: 24px;
    & > p {
      margin-top: 0px;
      margin-bottom: 8px;
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;
      color: #121212;
    }
    & > span {
      font-size: 14px;
      line-height: 20px;
      color: #6c6c6c;
      display: block;
    }
  }
}

.file-drop-target {
  background: none;
  font-size: inherit; /* 20 */
  position: inherit;
  padding: 0;
  border-radius: inherit;
}
.upload-content {
  display: flex;
  flex-direction: row;
  padding: 20px 0;
  .container {
    display: flex;
  }
  &__wrapper {
    padding: 40px 40px 100px 40px;
    margin: 0 15px 0 0;
    background: #ffffff;
    border: 1px solid $green-text;
    box-sizing: border-box;
    box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    width: calc(100% - 452px);
    h3 {
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: $base-color;
      margin: 0 0 15px;
    }
  }
  &__drag-drop {
    width: 100%;
    background: $gray-bg;
    border-radius: 10px;
    padding: 96px 15px 10px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 86px;
      left: 0;
      right: 0;
      height: 1px;
      background: $gray-text;
      opacity: 0.4;
    }
    &__old {
      padding: 0px 0 30px;
      &:after {
        display: none;
      }
      .upload-content__buttons {
        margin: 0;
        padding: 30px 0 0;
        .button-green {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          svg {
            margin: 0 20px 0 0;
          }
        }
      }
    }
    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: $dark-gray;
      text-align: center;
      span {
        display: block;
      }
    }
  }
  &__buttons {
    justify-content: space-around;
    display: flex;
    width: 100%;
    margin: 50px 0 0;
    padding: 30px 40px 0;
    border-top: solid 1px rgba(157, 157, 157, 0.4);
    box-sizing: border-box;

    button,
    label {
      width: 221px;
      height: 40px;
      &:nth-of-type(1) {
        align-self: flex-start;
      }
      &:nth-of-type(2) {
        align-self: flex-end;
      }
      svg {
        position: relative;
        margin: 1px 20px -2px 0;
      }
    }
    label {
      text-align: center;
      padding-top: 8px;
      cursor: pointer;
    }
  }
  &__text-edit {
    .form-group {
      position: relative;
      margin: 0 0 10px;
      svg {
        float: left;
        position: absolute;
        top: 0;
        left: 0;
      }
      input {
        width: 100%;
        height: 40px;
        padding: 0 10px 0 50px;
        box-sizing: border-box;
        font-size: 16px;
        font-weight: 600;
        border-radius: 3px;
      }
      textarea {
        width: 100%;
        height: 200px;
        padding: 15px 25px;
        box-sizing: border-box;
        font-size: 14px;
        resize: none;
        border-radius: 3px;
      }
    }
    &__buttons {
      margin-bottom: -20px;
      button {
        width: 96px;
        height: 34px;
        margin-right: 5px;
        &.button {
          &.button-green {
            box-shadow: 0 4px 5px rgba(0, 0, 0, 0.05);
            border: 1px solid transparent;
            transition: none;
            &:hover {
              box-shadow: none;
              border-color: $green-text;
            }
            &:focus,
            &:active {
              box-shadow: none;
              border: 2px solid rgba(14, 125, 125, 0.3);
            }
          }
        }
      }
    }
  }
  &__files {
    display: flex;
    flex-direction: row;
    background: $gray-bg;
    border-radius: 10px;
    padding: 75px 0 0;
    margin-bottom: -45px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 75px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: rgba(157, 157, 157, 0.4);
    }
  }
  &__files-list {
    width: calc(100% - 150px);
    margin: 20px 0;
    padding: 0 20px;
    .form-group {
      background-color: #ffffff;
      border-radius: 7px;
      position: static;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      &:not(:last-child) {
        margin: 0 0 10px;
      }
      p {
        width: calc(100% - 216px);
      }
      .upload-content__files-list__options {
        width: 23px;
        &-edit {
          width: 50px;
        }
      }
      &.has-manual {
        p {
          width: calc(100% - 228px);
        }
      }
      &.has-edit {
        p {
          width: calc(100% - 246px);
        }
      }
      svg {
        display: inline-block;
        position: relative;
        z-index: 2;
      }
      p {
        display: inline-block;
        margin: 0;
        padding: 0 30px;
        font-size: 14px;
        color: $base-color;
        width: calc(100% - 218px);
        box-sizing: border-box;
        @include truncate();
        @include transition(all, 0.2s);
      }
      span {
        width: 145px;
        text-align: right;
        &.in-quote {
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          color: $text-blue;
          svg {
            position: relative;
            margin: 0 0 -6px;
          }
        }
        &.upload-edit {
          b {
            font-weight: 600;
            font-size: 12px;
            color: $base-color;
            span {
              color: $gray-text;
              width: auto;
              margin: 0 0 0 5px;
            }
          }
        }
        &.uploading {
          b {
            color: $text-blue;
            font-weight: 600;
            font-size: 12px;
            span {
              width: auto;
              margin: 0 0 0 5px;
              color: $gray-text;
            }
          }
          .loading:after {
            content: '.';
            animation: dots 1s steps(5, end) infinite;
          }
          .loading-blue {
            margin-right: 5px;
            &:after {
              content: '.';
              animation: dots-blue 1s steps(5, end) infinite;
            }
          }
        }

        button {
          font-weight: 600;
          font-size: 12px;
          color: $gray-text;
        }
      }
      div {
        &.upload-manual {
          font-size: 12px;
          font-weight: 400;
          position: relative;
          width: auto;
          text-align: left;
          margin-left: auto;
          // margin-right: 10px;
          @include transition(all, 600ms);
          // transition: all 0.4s ease;
          .btn-preview-toggle {
            width: 51px;
            height: 19px;
            background: $picton-blue;
            border-radius: 10px;
            padding: 0;
            margin: 0 0 0 7px;
            position: relative;

            &:hover {
              background: $steel-blue;
            }

            &__text {
              font-weight: 600;
              font-size: 12px;
              line-height: 19px;
              color: white;
              text-align: center;
              display: block;
              position: relative;
              padding-right: 17px;
              transition: all 0.4s ease;

              &::before {
                content: '';
                width: 17px;
                height: 17px;
                position: absolute;
                transition: all 0.4s ease;
                right: 1px;
                top: 1px;
                border-radius: 50%;
                background: rgba(0, 0, 0, 0.25)
                  url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjEzIiBmaWxsPSJub25lIj48cGF0aCBkPSJNNS42NjUgMTAuODQ3bC0uMTU1LjYzMy0xLjExMi40MTljLS4yNzcuMDk2LS41OTguMTQ0LS45NjUuMTQ0LS41NjMgMC0xLS4xMzgtMS4zMTItLjQxMmExLjMzIDEuMzMgMCAwIDEtLjQ2OC0xLjA0NiAzLjcgMy43IDAgMCAxIC4wMzUtLjUwM2MuMDI0LS4xNzIuMDYyLS4zNjUuMTEzLS41ODFsLjU4MS0yLjA1Ni4xMzEtLjU2Yy4wMzYtLjE3NS4wNTMtLjMzNy4wNTMtLjQ4MiAwLS4yNjMtLjA1NC0uNDQ2LS4xNjMtLjU0OVMyLjA4OCA1LjcgMS43ODEgNS43YTEuNjMgMS42MyAwIDAgMC0uNDYyLjA3MWwtLjQwNi4xMzQuMTU1LS42MzQgMS4wOTItLjM5OGMuMzQ4LS4xMS42NzctLjE2Ni45ODgtLjE2Ni41NTkgMCAuOTkuMTM1IDEuMjkzLjQwNXMuNDU0LjYyMS40NTQgMS4wNTJjMCAuMDg5LS4wMS4yNDctLjAzMS40NzJzLS4wNi40MzItLjExNi42MmwtLjU3OCAyLjA0N2MtLjA0Ny4xNjQtLjA5LjM1Mi0uMTI3LjU2NHMtLjA1Ny4zNzEtLjA1Ny40NzhjMCAuMjcyLjA2MS40NTcuMTgyLjU1NnMuMzMzLjE0OC42MzMuMTQ4Yy4xNDEgMCAuMzAxLS4wMjUuNDc4LS4wNzRzLjMwNy0uMDkyLjM4Ny0uMTN6bS4xNDctOC41OTZjMCAuMzU3LS4xMzQuNjYxLS40MDUuOTEycy0uNTk0LjM3Ny0uOTc0LjM3N2ExLjM5IDEuMzkgMCAwIDEtLjk3OS0uMzc3Yy0uMjcyLS4yNTEtLjQwOC0uNTU1LS40MDgtLjkxMnMuMTM2LS42NjEuNDA4LS45MTUuNTk4LS4zOC45NzktLjM4LjcwNS4xMjcuOTc0LjM4YTEuMjEgMS4yMSAwIDAgMSAuNDA1LjkxNXoiIGZpbGw9IiNmZmYiLz48L3N2Zz4=')
                  center no-repeat;
              }
            }

            &__text:hover::before {
              background-color: $steel-blue;
            }
          }
          svg {
            margin: 0 0 -4px 0;
            cursor: pointer;
          }
        }
      }
      .button-edit {
        font-weight: 600;
        font-size: 12px;
        color: $gray-text;
        border-radius: 3px;
        margin-right: 3px;
        width: 23px;
        height: 23px;
        display: inline-block;
        vertical-align: middle;
        background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNSAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuMTEyMDMgMi41MDMzMUwxMi4wOTI5IDUuNTY5NTZMNC41NDc0NiAxMy4zMzEyTDEuNTY4MjYgMTAuMjY0OUw5LjExMjAzIDIuNTAzMzFaTTE0LjM1NDggMS43NjM4TDEzLjAyNTUgMC4zOTYzNTJDMTIuNTExNyAtMC4xMzIxMTcgMTEuNjc3NSAtMC4xMzIxMTcgMTEuMTYyIDAuMzk2MzUyTDkuODg4NiAxLjcwNjIzTDEyLjg2OTUgNC43NzI1MUwxNC4zNTQ4IDMuMjQ0NjFDMTQuNzUzMyAyLjgzNDcgMTQuNzUzMyAyLjE3MzY4IDE0LjM1NDggMS43NjM4Wk0wLjAwODI5NTEyIDE0LjU3NDhDLTAuMDQ1OTUzMyAxNC44MjU5IDAuMTc0NDc2IDE1LjA1MSAwLjQxODY0OSAxNC45ODk5TDMuNzQwMzUgMTQuMTYxNEwwLjc2MTE1MSAxMS4wOTUyTDAuMDA4Mjk1MTIgMTQuNTc0OFoiIGZpbGw9IiM5RDlEOUQiLz4KPC9zdmc+Cg==')
          center no-repeat;
        background-size: 13px auto;
        transition: all 0.4s ease;
        margin-right: 4px;
        flex-shrink: 0;

        svg {
          display: none;
        }

        &:hover {
          background: $rose-white
            url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgZmlsbD0ibm9uZSI+PHBhdGggZD0iTTkuMTEyIDIuNTAzbDIuOTgxIDMuMDY2LTcuNTQ1IDcuNzYyLTIuOTc5LTMuMDY2IDcuNTQ0LTcuNzYyem01LjI0My0uNzRMMTMuMDI2LjM5NmMtLjUxNC0uNTI4LTEuMzQ4LS41MjgtMS44NjMgMGwtMS4yNzMgMS4zMSAyLjk4MSAzLjA2NiAxLjQ4NS0xLjUyOGMuMzk5LS40MS4zOTktMS4wNzEgMC0xLjQ4MXpNLjAwOCAxNC41NzVjLS4wNTQuMjUxLjE2Ni40NzYuNDEuNDE1bDMuMzIyLS44MjgtMi45NzktMy4wNjYtLjc1MyAzLjQ4eiIgZmlsbD0iIzNmODhjNSIvPjwvc3ZnPg==')
            center no-repeat;
          background-size: 13px auto;
        }

        &:active {
          border: 1px solid $text-blue;
          background: $rose-white
            url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgZmlsbD0ibm9uZSI+PHBhdGggZD0iTTkuMTEyIDIuNTAzbDIuOTgxIDMuMDY2LTcuNTQ1IDcuNzYyLTIuOTc5LTMuMDY2IDcuNTQ0LTcuNzYyem01LjI0My0uNzRMMTMuMDI2LjM5NmMtLjUxNC0uNTI4LTEuMzQ4LS41MjgtMS44NjMgMGwtMS4yNzMgMS4zMSAyLjk4MSAzLjA2NiAxLjQ4NS0xLjUyOGMuMzk5LS40MS4zOTktMS4wNzEgMC0xLjQ4MXpNLjAwOCAxNC41NzVjLS4wNTQuMjUxLjE2Ni40NzYuNDEuNDE1bDMuMzIyLS44MjgtMi45NzktMy4wNjYtLjc1MyAzLjQ4eiIgZmlsbD0iIzNmODhjNSIvPjwvc3ZnPg==')
            center no-repeat;
          background-size: 13px auto;
        }
      }
      .button-close {
        border-radius: 3px;
        width: 23px;
        height: 23px;
        display: inline-block;
        vertical-align: middle;
        flex-shrink: 0;
        background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgZmlsbD0iIzlkOWQ5ZCI+PHBhdGggZD0iTTE0LjY1OSAxMy4xOTNMMTIuODUxIDE1IDAgMi4xNDkgMS44MDcuMzQxeiIvPjxwYXRoIGQ9Ik0xMi44NTIgMGwxLjgwNyAxLjgwN0wxLjgwOCAxNC42NTkuMDAxIDEyLjg1MXoiLz48L3N2Zz4=')
          center no-repeat;
        transition: all 0.4s ease;
        background-size: 13px auto;

        svg {
          top: 1px;
          display: none;
        }

        &:hover {
          background: rgba(255, 91, 91, 0.1)
            url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgZmlsbD0iI2ZmNWI1YiI+PHBhdGggZD0iTTE0LjY1OSAxMy4xOTNMMTIuODUxIDE1IDAgMi4xNDkgMS44MDcuMzQxeiIvPjxwYXRoIGQ9Ik0xMi44NTIgMGwxLjgwNyAxLjgwN0wxLjgwOCAxNC42NTkuMDAxIDEyLjg1MXoiLz48L3N2Zz4=')
            center no-repeat;
          background-size: 13px auto;
        }

        &:active {
          background: rgba(255, 91, 91, 0.1)
            url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgZmlsbD0iI2ZmNWI1YiI+PHBhdGggZD0iTTE0LjY1OSAxMy4xOTNMMTIuODUxIDE1IDAgMi4xNDkgMS44MDcuMzQxeiIvPjxwYXRoIGQ9Ik0xMi44NTIgMGwxLjgwNyAxLjgwN0wxLjgwOCAxNC42NTkuMDAxIDEyLjg1MXoiLz48L3N2Zz4=')
            center no-repeat;
          border: 1px solid $red-text;
          background-size: 13px auto;
        }
      }
      .uploading-loadbar {
        // width: calc(100% - 37px);
        // flex: 0 1 calc(100% - 37px);
        // margin-left: 32px;
        border-radius: 0 0 7px 0;
        overflow: hidden;
        position: absolute;
        left: 32px;
        bottom: 0;
        right: 2px;
      }
      .upload-manual-review {
        background: #ffffff;
        box-shadow: 3px 5px 30px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        padding: 20px 20px 16px;
        width: 238px;
        position: absolute;
        left: 7px;
        bottom: 22px;
        z-index: 9;
        display: none;
        &-visible {
          display: block;
        }
        b {
          font-size: 14px;
          font-weight: 600;
          color: $base-color;
          margin-bottom: 10px;
          display: block;
        }
        p {
          font-size: 12px;
          color: $base-color;
          padding: 0;
          width: auto !important;
          white-space: inherit;
        }
      }
    }
    &__options {
      width: 0;
      display: none;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      overflow: hidden;
      @include transition(all, 200ms);
      transition: all 0.4s ease;
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -(23px / 2);

      &-edit {
        width: 0;
      }

      .form-group_hint & {
        right: -35px;
        display: flex;
        transition: all 0.4s ease;
      }
    }

    .form-group {
      &__buttons {
        overflow: hidden;
        width: 0;
        padding-right: 10px;
        transition: all 0.4s ease;
        position: relative;
        height: 23px;
      }

      &:hover {
        box-shadow: 0 0 0 1px $glacier;

        & > svg,
        & > img {
          border-radius: 7px 0 0 7px;
        }
      }
    }

    .form-group_hint:hover &__options {
      right: 10px;
    }

    .form-group_hint:hover .form-group__buttons {
      width: 40px;
      padding-right: 10px;
    }

    .form-group:hover &__options {
      display: flex;
    }

    .form-group {
      position: relative;
      flex-wrap: nowrap;

      &_loadbar {
        padding-bottom: 3px;
      }

      .uploading,
      .in-quote,
      .upload-edit {
        margin-right: 10px;
      }
    }

    .form-group:hover {
      .uploading,
      .in-quote,
      .upload-edit {
        display: none;
      }
    }
  }
  &__files-upload__button {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 0;
    button {
      padding: 6px 12px;
      margin-left: -10px;
      position: relative;
      &.button {
        &.button-green {
          font-weight: 600;
          font-size: 14px;
          &:hover {
            color: $dark-green;
            border-color: $dark-green;
            background: #ffffff;
            svg {
              path {
                fill: $dark-green;
                stroke: none;
              }
            }
          }
          &:focus,
          &.active {
            border-color: rgba(14, 125, 125, 0.3);
            color: $green-text;
            background: #ffffff;
            box-shadow: none;
            svg {
              path {
                fill: $green-text;
                stroke: none;
              }
            }
          }
        }
      }
      svg {
        &:nth-of-type(1) {
          margin: 0 10px 0 0;
        }
        &:nth-of-type(2) {
          margin: 0 0 0 10px;
          top: -2px;
          position: relative;
        }
      }
      span {
        display: block;
      }
    }
    .dropdown {
      display: none;
      position: absolute;
      padding: 5px;
      border: 0;
      top: calc(100% + 10px);
      right: 0;
      background: #ffffff;
      box-shadow: 0 4px 5px rgba(0, 0, 0, 0.05);
      border-radius: 3px;
      opacity: 9;
      &.active {
        display: block;
      }
      ul {
        li {
          display: block;
          a {
            white-space: nowrap;
            text-align: left;
            display: block;
            color: $green-text;
            border-radius: 3px;
            padding: 8px 6px;
            border: solid 1px transparent;
            &:hover {
              background: rgba(14, 123, 123, 0.1);
            }
            &:active {
              border: 1px solid #b7d8d8;
              background: #ffffff;
            }
            svg {
              margin-bottom: -5px;
            }
          }
        }
      }
    }
  }
}

.wrap-btn-add {
  justify-content: space-around;
  display: flex;
  width: 100%;
  padding: 30px 40px 1px;
  border-top: solid 1px rgba(157, 157, 157, 0.4);
}

.btn-add {
  position: relative;
  display: inline-block;
  vertical-align: middle;

  &_second &__header &__input-label {
    padding-right: 15px;
    padding-left: 45px;
  }

  &__header {
    box-shadow: inset 0 0 0 1px $green-text, 0 4px 5px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    background: $white;
  }

  &__input {
    position: relative;

    input[type='file'] {
      display: none;
    }

    &-label {
      border: none;
      color: $green-text;
      text-align: left;
      font-size: 14px;
      line-height: 1.5;
      font-weight: 600;
      display: block;
      padding: 8px 10px 9px 40px;
      cursor: pointer;
      background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNyAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjY3MzIgNy44MjUzNUMxNS40MzI3IDcuODI1MzUgMTUuMjQwNCA4LjAxNzcgMTUuMjQwNCA4LjI1ODE0VjEyLjE5NDlDMTUuMjQwNCAxMy4yNjU3IDE0LjM2ODQgMTQuMTM0NSAxMy4zMDA4IDE0LjEzNDVIMy44MDUxMkMyLjczNDM2IDE0LjEzNDUgMS44NjU1OCAxMy4yNjI1IDEuODY1NTggMTIuMTk0OVY4LjE5NDAyQzEuODY1NTggNy45NTM1OCAxLjY3MzIzIDcuNzYxMjMgMS40MzI3OSA3Ljc2MTIzQzEuMTkyMzUgNy43NjEyMyAxIDcuOTUzNTggMSA4LjE5NDAyVjEyLjE5NDlDMSAxMy43NDMzIDIuMjU5OSAxNSAzLjgwNTEyIDE1SDEzLjMwMDhDMTQuODQ5MyAxNSAxNi4xMDU5IDEzLjc0MDEgMTYuMTA1OSAxMi4xOTQ5VjguMjU4MTRDMTYuMTA1OSA4LjAyMDkgMTUuOTEzNiA3LjgyNTM1IDE1LjY3MzIgNy44MjUzNVoiIGZpbGw9IiMwRTdEN0QiIHN0cm9rZT0iIzBFN0Q3RCIgc3Ryb2tlLXdpZHRoPSIwLjUiLz4KPHBhdGggZD0iTTYuMTA2OSA0LjQ5MTE3TDguMTIwMTggMi40Nzc5VjExLjI1ODdDOC4xMjAxOCAxMS40OTkxIDguMzEyNTMgMTEuNjkxNSA4LjU1Mjk3IDExLjY5MTVDOC43OTM0IDExLjY5MTUgOC45ODU3NSAxMS40OTkxIDguOTg1NzUgMTEuMjU4N1YyLjQ3NzlMMTAuOTk5IDQuNDkxMTdDMTEuMDgyNCA0LjU3NDUyIDExLjE5NDYgNC42MTk0IDExLjMwMzYgNC42MTk0QzExLjQxNTggNC42MTk0IDExLjUyNDggNC41Nzc3MiAxMS42MDgxIDQuNDkxMTdDMTEuNzc4IDQuMzIxMjYgMTEuNzc4IDQuMDQ4NzYgMTEuNjA4MSAzLjg3ODg1TDguODU3NTIgMS4xMjgyM0M4Ljc3NzM3IDEuMDQ4MDkgOC42NjUxNyAxIDguNTUyOTcgMUM4LjQzNzU2IDEgOC4zMjg1NiAxLjA0NDg4IDguMjQ4NDEgMS4xMjgyM0w1LjQ5Nzc5IDMuODc4ODVDNS4zMjc4OCA0LjA0ODc2IDUuMzI3ODggNC4zMjEyNiA1LjQ5Nzc5IDQuNDkxMTdDNS42NjQ1IDQuNjU3ODcgNS45NDAyIDQuNjU3ODcgNi4xMDY5IDQuNDkxMTdaIiBmaWxsPSIjMEU3RDdEIiBzdHJva2U9IiMwRTdEN0QiIHN0cm9rZS13aWR0aD0iMC41Ii8+Cjwvc3ZnPgo=')
        10px center no-repeat;
    }

    &_add &-label {
      color: $green-text;
      background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcgMEg5VjE2SDdWMFoiIGZpbGw9IiMwRTdEN0QiLz4KPHBhdGggZD0iTTE2IDdMMTYgOUwtOC43NDIyOGUtMDggOUwwIDdMMTYgN1oiIGZpbGw9IiMwRTdEN0QiLz4KPC9zdmc+Cg==')
        19px center no-repeat;
      border-radius: 4px;
      height: 45px;
      line-height: 45px;
      padding: 0 31px 0 55px;
      transition: all 0.4s ease;

      &:hover {
        color: $dark-green;
        box-shadow: inset 0 0 0 2px #0e7d7d;
        background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iIzBhNjE2MSI+PHBhdGggZD0iTTcgMEg5VjE2SDdWMFoiLz48cGF0aCBkPSJNMTYgN3YySDBWN2gxNnoiLz48L3N2Zz4=')
          19px center no-repeat;
      }

      &:active {
        color: $dark-green;
        box-shadow: inset 0 0 0 3px rgba(14, 125, 125, 0.3);
        background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iIzBhNjE2MSI+PHBhdGggZD0iTTcgMEg5VjE2SDdWMFoiLz48cGF0aCBkPSJNMTYgN3YySDBWN2gxNnoiLz48L3N2Zz4=')
          19px center no-repeat;
      }
    }
  }

  &__dropdown &__input-label {
    border: 1px solid $white;
    border-radius: 3px;

    &:hover {
      background-color: rgba(14, 123, 123, 0.1);
    }

    &:active {
      background-color: $white;
      border-color: $scandal-color;
    }
  }

  &__icon {
    width: 45px;
    height: 45px;
    cursor: pointer;
    border-radius: 4px;
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDExIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01LjUgNi43NzUxM0wwIDAuODUyMDUxSDExTDUuNSA2Ljc3NTEzWiIgZmlsbD0iIzBFN0Q3RCIvPgo8L3N2Zz4K')
      center no-repeat;
    transition: all 0.4s ease;
    position: relative;

    &:hover {
      box-shadow: inset 0 0 0 2px #0e7d7d;
      background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDExIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01LjUgNi43NzUxM0wwIDAuODUyMDUxSDExTDUuNSA2Ljc3NTEzWiIgZmlsbD0iIzBBNjE2MSIvPgo8L3N2Zz4K')
        center no-repeat;
    }

    &:active {
      box-shadow: inset 0 0 0 3px rgba(14, 125, 125, 0.3);
      background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDExIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01LjUgNi43NzUxM0wwIDAuODUyMDUxSDExTDUuNSA2Ljc3NTEzWiIgZmlsbD0iIzBBNjE2MSIvPgo8L3N2Zz4K')
        center no-repeat;
    }
  }

  &_opened &__icon {
    box-shadow: inset 0 0 0 3px rgba(14, 125, 125, 0.3);
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDExIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01LjUgNi43NzUxM0wwIDAuODUyMDUxSDExTDUuNSA2Ljc3NTEzWiIgZmlsbD0iIzBBNjE2MSIvPgo8L3N2Zz4K')
      center no-repeat;
  }

  &__link {
    display: block;
    padding: 8px 10px 9px 40px;
    border-radius: 3px;
    border: 1px solid $white;
    transition: all 0.4s ease;
    position: relative;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: $green-text;
    text-decoration: none;

    &::before {
      content: '';
      position: absolute;
      left: 10px;
      top: 50%;
      width: 20px;
      height: 20px;
      margin-top: -10px;
    }

    &_text::before {
      background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIxOSIgZmlsbD0iIzBlN2Q3ZCIgIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIuMSI+PHBhdGggZD0iTTE5LjU1IDEyLjMyMmEuNjkyLjY5MiAwIDEgMS0xLjM4NCAwVjIuOTY5YzAtLjg0Ni0uNjg4LTEuNTM0LTEuNTM0LTEuNTM0SDMuNTY5Yy0uODQ2IDAtMS41MzQuNjg4LTEuNTM0IDEuNTM0djEzLjA2MmMwIC44NDYuNjg4IDEuNTM0IDEuNTM0IDEuNTM0aDEzLjA2MmMuODQ2IDAgMS41MzQtLjY4OCAxLjUzNC0xLjUzNGEuNjkyLjY5MiAwIDAgMSAxLjM4NCAwYzAgMS42MDktMS4zMDkgMi45MTktMi45MTkgMi45MTlIMy41NjljLTEuNjEgMC0yLjkxOS0xLjMwOS0yLjkxOS0yLjkxOVYyLjk2OUMuNjUgMS4zNTkgMS45NTkuMDUgMy41NjkuMDVoMTMuMDYyYzEuNjA5IDAgMi45MTkgMS4zMDkgMi45MTkgMi45MTl2OS4zNTR6bS0zLjY2MS0zLjUxNGEuNjkyLjY5MiAwIDEgMSAwIDEuMzg0SDQuMzQ4YS42OTIuNjkyIDAgMSAxIDAtMS4zODRoMTEuNTQxek0zLjY1NiAxMi40NjlhLjY5LjY5IDAgMCAxIC42OTItLjY5Mmg2LjkzOWEuNjkyLjY5MiAwIDEgMSAwIDEuMzg0SDQuMzQ4YS42OS42OSAwIDAgMS0uNjkyLS42OTJ6bTkuMTE2LTUuMjQ2SDQuMzQ4YS42OTIuNjkyIDAgMSAxIDAtMS4zODRoOC40MjRhLjY5Mi42OTIgMCAxIDEgMCAxLjM4NHoiLz48L3N2Zz4=')
        center no-repeat;
    }

    &_url::before {
      background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxOSAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuMTk0OTUgMTcuODA1MkwxLjE5NDk2IDE3LjgwNTJDMi43MjEwNCAxOS4zMzIzIDUuMTk1MzcgMTkuMzMyMyA2LjcyMjQ3IDE3LjgwNTJMOC45NjEzOCAxNS41NjYzQzkuMjUxMDMgMTUuMjc2NiA5LjI1MTAzIDE0LjgwNyA4Ljk2MTM4IDE0LjUxNzRDOC42NzE3NCAxNC4yMjc3IDguMjAyMDggMTQuMjI3OCA3LjkxMjQzIDE0LjUxNzRMNS42NzM1MiAxNi43NTYzQzQuNzI1OCAxNy43MDQgMy4xOTA5OCAxNy43MDQxIDIuMjQ0MDEgMTYuNzU2NUwxLjE5NDk1IDE3LjgwNTJaTTEuMTk0OTUgMTcuODA1MkMtMC4zMzEzMjQgMTYuMjc4OSAtMC4zMzEzMjQgMTMuODA0OCAxLjE5NDk1IDEyLjI3ODVMNS42NzM1MiA3Ljc5OTkzQzcuMTk5NzkgNi4yNzM2NiA5LjY3Mzk1IDYuMjczNjYgMTEuMjAwMiA3Ljc5OTkzQzExLjQ4OTkgOC4wODk1NyAxMS40ODk5IDguNTU5MiAxMS4yMDAyIDguODQ4ODRDMTAuOTEwNiA5LjEzODQ4IDEwLjQ0MSA5LjEzODQ4IDEwLjE1MTMgOC44NDg4NEM5LjIwNDM2IDcuOTAxODUgNy42NjkzOSA3LjkwMTg1IDYuNzIyNDMgOC44NDg4NEwyLjI0MzgzIDEzLjMyNzRDMS4yOTY4NyAxNC4yNzQ0IDEuMjk2ODcgMTUuODA5NCAyLjI0NCAxNi43NTY1TDEuMTk0OTUgMTcuODA1MloiIGZpbGw9IiMwRTdEN0QiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMC4xIi8+CjxwYXRoIGQ9Ik0xMi4yNzggMS4xOTQ3MUMxMy44MDQzIC0wLjMzMTU2OSAxNi4yNzkyIC0wLjMzMTU2OSAxNy44MDU1IDEuMTk0NzFDMTkuMzMxOCAyLjcyMDk4IDE5LjMzMTggNS4xOTUxNyAxNy44MDU1IDYuNzIxNDVMMTIuODc5NiAxMS42NDczQzExLjM1MzQgMTMuMTczNiA4Ljg3OTIgMTMuMTczNiA3LjM1MjkyIDExLjY0NzNDNy4wNjMyOCAxMS4zNTc3IDcuMDYzMjggMTAuODg4MSA3LjM1MjkyIDEwLjU5ODRDNy42NDI1NyAxMC4zMDg4IDguMTEyMiAxMC4zMDg4IDguNDAxODQgMTAuNTk4NEM5LjM0ODc5IDExLjU0NTQgMTAuODgzNyAxMS41NDU0IDExLjgzMDcgMTAuNTk4NEwxNi43NTY2IDUuNjcyNUMxNy43MDM2IDQuNzI1NTQgMTcuNzAzNiAzLjE5MDU3IDE2Ljc1NjYgMi4yNDM2MkMxNS44MDk2IDEuMjk2NjMgMTQuMjczOSAxLjI5NjYzIDEzLjMyNjkgMi4yNDM2MkwxMC42NDA3IDQuOTI5ODNDMTAuMzUxMSA1LjIxOTQ3IDkuODgxNDQgNS4yMTk0NyA5LjU5MTggNC45Mjk4M0M5LjMwMjE2IDQuNjQwMTggOS4zMDIxNiA0LjE3MDU1IDkuNTkxOCAzLjg4MDkxTDEyLjI3OCAxLjE5NDcxWiIgZmlsbD0iIzBFN0Q3RCIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIwLjEiLz4KPC9zdmc+Cg==')
        center no-repeat;
    }

    &:hover {
      background: rgba(14, 123, 123, 0.1);
    }

    &:active {
      background-color: $white;
      border-color: $scandal-color;
      text-decoration: none;
    }
  }

  &__dropdown {
    position: absolute;
    right: 0;
    top: 100%;
    display: none;
    background: $white;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    min-width: 100%;
    padding: 5px;
    margin-top: 7px;
    white-space: nowrap;
  }

  &_opened &__dropdown {
    display: block;
  }
}

.paym__document--box .switch__wrapper > .el {
  margin-bottom: 8px;
  padding: 16px;
  border: 1px solid #dadada;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.4s ease all;
  position: relative;
  &.opened {
    background-color: #f6f6f6;
  }
}
#payment-form {
  padding: 10px;
}

.visual__popup--translator,
.visual__popup--tools {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.5);
  & > .inner {
    width: 100%;
    height: 100%;
    padding: 25px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    & > .box__wrapper {
      max-width: 800px;
      width: 100%;
      padding: 24px;
      border-radius: 12px;
      background-color: #ffffff;
      box-shadow: 6px 6px 30px 0px rgba(181, 181, 181, 0.25);
      & .translators__list--popup,
      & .tools__list--popup {
        height: auto;
        max-height: calc(100vh - 230px);
        overflow-y: auto;
        & > .elem {
          margin-bottom: 8px;
          & > label {
            display: flex;
            padding: 16px;
            align-items: center;
            justify-content: flex-start;
            border-radius: 12px;
            background-color: #f6f6f6;
            & .inner__translator,
            & .inner__tools {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              margin-left: 24px;
              & > .desc {
                width: 100%;
                & > ul {
                  padding: 0px;
                  list-style-type: none;
                  margin: 0px;
                  & > li {
                    display: flex;
                    align-items: center;
                    margin-bottom: 8px;
                    &:last-child {
                      margin-bottom: 0px;
                    }
                    justify-content: flex-start;
                    & > p {
                      margin: 0px;
                      font-size: 14px;
                      line-height: 18px;
                      color: #121212;
                      font-weight: 500;
                    }
                    & > span {
                      display: block;
                      min-width: 110px;
                      max-width: 110px;
                      margin-right: 8px;
                      font-size: 14px;
                      line-height: 18px;
                      color: #6c6c6c;
                      font-weight: 500;
                    }
                  }
                }
                & > p {
                  font-size: 16px;
                  line-height: 20px;
                  font-weight: 600;
                  color: #121212;
                  margin-top: 0px;
                  margin-bottom: 12px;
                }
              }
              & > .img {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                min-width: 64px;
                max-width: 64px;
                min-height: 64px;
                max-height: 64px;
                border-radius: 250px;
                margin-right: 16px;
                background-color: #dedede;
                & > img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
            align-items: center;
            justify-content: flex-start;
          }
        }
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
          border-radius: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #fff;
        }

        &::-webkit-scrollbar-thumb {
          background: #ebebef;
          border-radius: 5px;
        }
      }
      & .btns {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 24px;
        & > p {
          margin: 0px;
          margin-right: 24px;
          font-size: 14px;
          line-height: 24px;
          font-weight: 600;
          color: #121212;
        }
        & > a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          min-height: 48px;
          min-width: 160px;
          border-radius: 6px;
          text-decoration: none;
          background-color: #0e7d7d;
          font-weight: 600;
          color: #fff;
          text-decoration: none;
          transition: 0.3s ease all;
          &:hover {
            background-color: #0a5858;
          }
          &:active {
            box-shadow: 0 0 0 0.2rem rgba(14, 125, 125, 0.5);
            background-color: #094f4f;
          }
        }
      }
      & > .top {
        text-align: center;
        margin-bottom: 24px;
        & > p {
          text-align: center;
          margin-top: 0px;
          margin-bottom: 8px;
          font-size: 20px;
          line-height: 24px;
          font-weight: bold;
          color: #121212;
        }
        & > span {
          display: block;
          text-align: center;
          margin: 0px;
          font-size: 16px;
          line-height: 24px;
          color: #121212;
          font-weight: 600;
        }
      }
    }
  }
}
.visual__popup--tools
  > .inner
  > .box__wrapper
  .tools__list--popup
  > .elem
  > label
  .inner__tools
  > .img {
  background-color: #dedede;
}
.visual__popup--translators
  > .inner
  > .box__wrapper
  .translators__list--popup
  > .elem
  > label
  .inner__translator
  > .img {
  background-color: #dedede;
}
.location__popup {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.5);
  & > .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 30px 25px;
    & > .box__location {
      width: 100%;
      max-width: 800px;
      border-radius: 12px;
      padding: 24px;
      background-color: #ffffff;
      box-shadow: 6px 6px 30px 0px rgba(181, 181, 181, 0.25);
      & .group__input {
        margin-bottom: 16px;
        & > p {
          margin-top: 0px;
          margin-bottom: 16px;
          font-size: 14px;
          line-height: 22px;
          color: #121212;
          font-weight: bold;
        }
        & > input {
          width: 100%;
          max-width: 320px;
          border-radius: 6px;
          border: 1px solid #dadada;
          padding-left: 16px;
          padding-right: 56px;
          font-size: 14px;
          line-height: 24px;
          color: #000;
          outline: none;
          height: 48px;
          //background:url('../images/geoicon.svg');
          background-repeat: no-repeat;
          background-size: 24px;
          background-position: calc(100% - 12px) center;
        }
      }
      & #map {
        & iframe {
          height: 220px;
          border-radius: 12px;
        }
      }
      & .btns {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 24px;
        & > p {
          margin: 0px;
          margin-right: 24px;
          font-size: 14px;
          line-height: 24px;
          font-weight: 600;
          color: #121212;
        }
        & > a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          min-height: 48px;
          min-width: 160px;
          border-radius: 6px;
          text-decoration: none;
          background-color: #0e7d7d;
          font-weight: 600;
          color: #fff;
          text-decoration: none;
          transition: 0.3s ease all;
          &:hover {
            background-color: #0a5858;
          }
          &:active {
            box-shadow: 0 0 0 0.2rem rgba(14, 125, 125, 0.5);
            background-color: #094f4f;
          }
        }
      }
      & > .top {
        text-align: center;
        margin-bottom: 24px;
        & > p {
          text-align: center;
          margin-top: 0px;
          margin-bottom: 8px;
          font-size: 20px;
          line-height: 24px;
          font-weight: bold;
          color: #121212;
        }
        & > span {
          display: block;
          text-align: center;
          margin: 0px;
          font-size: 16px;
          line-height: 24px;
          color: #121212;
          font-weight: 600;
        }
      }
    }
  }
}

.translation__picker--element {
  & .side__field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .right__controls {
      & > a {
        white-space: nowrap;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 12px;
        line-height: 18px;
        font-weight: bold;
        color: #6c6c6c;
        transition: 0.3s ease all;
        &:hover {
          opacity: 0.7;
        }
        & span {
          display: inline-flex;
          margin-left: 8px;
          align-items: center;
          justify-content: center;
          & img {
            min-width: 16px;
            max-width: 16px;
          }
        }
      }
    }
    & .left__controls {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-start;
      & > .permissions__field {
        width: 400px;
        margin-right: 16px;
      }
      & > .input-animation {
        width: 400px;
        margin-right: 16px;
      }
      & > .field {
        width: 100%;
        max-width: 320px;
        & > input {
          width: 100%;
          height: 40px;
          border: 1px solid #dadada;
          border-radius: 6px;
          padding-left: 16px;
          padding-right: 56px;
          font-size: 14px;
          line-height: 24px;
          color: #000;
          &.address__field {
            //background:url('../images/geoicon.svg');
            background-repeat: no-repeat;
            background-size: 24px;
            background-position: calc(100% - 12px) center;
          }
        }
      }
      & > .select.is-expanded .select__label {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      & > .select {
        & .select__dropdown {
          margin-top: -1px;
        }
        width: 100%;
        max-width: 320px;
        > button {
          &:before {
            content: none;
          }
          &:after {
            //background:url('../images/arrowblack.svg');
            background-size: 11px 6px;
            background-position: center;
            background-repeat: no-repeat;
          }
          border: 1px solid #dadada;
          background-color: #fff;
          border-radius: 6px;
        }
      }
      & > a {
        display: inline-flex;
        margin-left: 16px;
        font-size: 12px;
        line-height: 18px;
        color: #6c6c6c;
        font-weight: 500;
        text-decoration: underline !important;
      }
    }
  }
}

.irs-line {
  height: 40px !important;
}

.irs--flat.irs-with-grid {
  height: 60px !important;
}

.irs-with-grid .irs-grid {
  top: 56px !important;
}

.irs--flat .irs-bar {
  top: 25px !important;
  height: 40px !important;
  background-color: #baf0c0 !important;
  border-left: 1px solid #59c264 !important;
  border-right: 1px solid #59c264 !important;
}

.length__field--wrapper .irs-grid > span {
  font-weight: 600 !important;
  color: #6c6c6c !important;
}

.length__field--wrapper .irs-grid-pol {
  top: 1px !important;
  background-color: #b5b5b5 !important;
  z-index: 3 !important;
}

.irs-grid-pol.small {
  height: 8px !important;
}

.length__field--wrapper .irs-handle.from {
  min-width: 14px !important;
  top: 31px !important;
  min-height: 24px !important;
  max-height: 24px !important;
  max-width: 14px !important;
  background: url('../images/handler.svg') !important;
  background-repeat: no-repeat !important;
  cursor: pointer !important;
  background-size: contain !important;
  background-position: center !important;
}

.length__field--wrapper .irs-handle.to {
  min-width: 14px !important;
  top: 31px !important;
  min-height: 24px !important;
  max-height: 24px !important;
  max-width: 14px !important;
  background: url('../images/handler.svg') !important;
  background-repeat: no-repeat !important;
  cursor: pointer !important;
  background-size: contain !important;
  background-position: center !important;
}

.irs-from {
  display: none !important;
}

.irs-to {
  display: none !important;
}

.irs-min {
  display: none !important;
}

.irs-max {
  display: none !important;
}

.irs--flat .irs-handle > i:first-child {
  display: none !important;
}

.field__element > p {
  margin-top: 0px;
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 24px;
  color: #121212;
  font-weight: bold;
}

.field__element.long .drop {
  max-width: 100%;
}

.field__element .drop {
  width: 100%;
  max-width: 320px;
  position: relative;
}

.field__element .drop > a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: 40px;
  border: 1px solid #dadada;
  border-radius: 6px;
  text-decoration: none;
}

.field__element .drop > a span.not__picked {
  color: #121212;
}

.field__element .drop > a span {
  position: absolute;
  left: 16px;
  width: calc(100% - 30px);
  font-size: 12px;
  line-height: 16px;
  color: #000;
  font-weight: 600;
}

.field__element .drop > a > img {
  position: absolute;
  right: 16px;
  width: 12px;
  transition: 0.3s ease all;
}

.field__element .drop > a.opened > img {
  transform: rotate(180deg);
}

.field__element .drop .drop__box.style__drop {
  max-height: none;
  overflow-y: initial;
  padding-bottom: 20px;
}

.field__element .drop .drop__box {
  border: 1px solid #bcbcbc;
  border-radius: 8px;
  top: 56px;
  box-shadow: 6px 6px 30px 0px rgba(181, 181, 181, 0.25);
  background-color: #fff;
  z-index: 4;
  padding: 10px;
  position: absolute;
  left: 0px;
  width: 100%;
  max-height: 240px;
  overflow-y: auto;
}

.field__element .drop .drop__box.style__drop > .group__input {
  width: 100%;
  margin-bottom: 12px;
}

.field__element .drop .drop__box.style__drop > .group__input > input {
  width: 100%;
  height: 40px;
  padding-left: 12px;
  outline: none;
  font-size: 12px;
  line-height: 18px;
  color: #000;
  font-weight: 500;
  border: 1px solid #dadada;
  border-radius: 6px;
}

.field__element .drop .drop__box.style__drop .list {
  max-height: 340px;
  /*   overflow-y: auto; */
}

.field__element .drop .drop__box.style__drop .list .table__list {
  margin-bottom: 12px;
}

.field__element .drop .drop__box.style__drop .list .table__list > .el {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 8px;
  margin-bottom: 4px;
  width: 350px;
}

.field__element .drop .drop__box.style__drop .list .table__list > .el > p {
  margin: 0px;
  font-size: 14px;
  line-height: 21px;
  color: #6c6c6c;
  font-weight: 600;
  min-width: 90px;
  margin-right: 12px;
  max-width: 90px;
}

.field__element .drop .drop__box.style__drop .list .table__list > .el > span {
  display: block;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
}
.field__element .drop .drop__box.style__drop .list .table__list > .el > input {
  border: 1px solid #bcbcbc;
  border-radius: 4px;
  height: 36px;
}

.field__element .drop .drop__box.style__drop .list .table__list > .el:last-child {
  margin-bottom: 0px;
}

.field__element .drop .drop__box.style__drop .list .btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.field__element .drop .drop__box.style__drop .list .btn > a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 40px;
  max-height: 70px;
  border-radius: 4px;
  border: 1px solid #0e7d7d;
  text-decoration: none;
  font-size: 14px;
  line-height: 24px;
  color: #0e7d7d;
  font-weight: 600;
}

.field__element .drop .drop__box.style__drop .list .field__ {
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 12px;
}

.field__element .drop .drop__box.style__drop .list .field__ > p {
  margin-top: 0px;
  font-size: 14px;
  line-height: 19px;
  color: #121212;
  font-weight: bold;
}
.client_order_datapicker {
  padding: 10px 0 0 20px;
}

.client_order_datapicker > p {
  font-weight: bold;
  margin-bottom: 8px;
}
.client_order_datapicker > .datepicker-container {
  width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.datepicker-container > .datepicker-content {
  width: 190px
}
