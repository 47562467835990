.client_order_wrapper {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px 0px rgba(138, 138, 138, 0.2);
  margin-bottom: 16px;
  padding: 16px;
  & .catalog {
    & .catalog__box {
      box-shadow: none;
    }
  }
  & .order__table {
    overflow: auto;
    box-shadow: none;
    & .table__wrapper {
      border: 1px solid #dadada;
      border-radius: 8px;
      padding-bottom: 10px !important;
    }
    & .download__all {
      margin-top: 20px;
    }
  }
  & .table__wrapper .table__common tr > .expandable__row.expandable__job {
    padding-left: 15px !important;
  }
}

.element__holder.delivery {
  margin-bottom: 24px;
  & .expandable__stick td {
    padding-bottom: 7px !important;
  }
  & .download__all {
    padding-right: 0px;
  }
  & .table__wrapper {
    border: 1px solid #dadada;
    border-radius: 8px;
    & .file__desc {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      & span {
        display: inline-flex;
        min-width: 32px;
        max-width: 32px;
        margin-right: 16px;
        align-items: center;
        justify-content: center;
        & img {
          min-width: 32px;
          max-width: 32px;
        }
      }
    }
    & tr {
      border-bottom: 1px solid #e3e3e3;
    }
    & tr td > p {
      color: #121212 !important;
    }
    & tr th {
      padding-top: 9px !important;
      & h4 {
        font-size: 12px;
        line-height: 16px;
        color: #7e7e7e;
      }
    }
    & .file__table tr:nth-child(1) td {
      border-top: 0px !important;
      padding-left: 30px;
    }
    & table.table__common.table__lines td {
      border-bottom: 0px;
      border-top: 1px solid #e3e3e3;
    }
    & table.table__common.table__lines > tbody > tr:nth-child(2) > td {
      border-bottom: 0px;
      border-top: 0px;
    }
    & table.table__common.padding-start tr th:nth-child(1) {
      padding-left: 30px;
    }
  }
  & .btn__end {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
  }
  & > p {
    margin: 0px;
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 18px;
    color: #0e7d7d;
    font-weight: bold;
  }
  &:last-child {
    margin-bottom: 0px;
  }
}

.delivery__tooltip {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px;
  border-radius: 8px;
  background-color: #f5f5f5;
  margin-top: 24px;
  & .desc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 4px;
    & > p {
      margin-top: 0px;
      margin-bottom: 4px;
      font-size: 14px;
      line-height: 18px;
      color: #121212;
      font-weight: bold;
    }
    & > span {
      display: block;
      font-size: 14px;
      line-height: 18px;
      color: #737373;
      font-weight: 400;
      & span {
        font-weight: bold;
        color: #2a2a2a;
      }
    }
  }
  & > span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 48px;
    margin-right: 16px;
    max-width: 48px;
    min-height: 48px;
    max-height: 48px;
    border-radius: 6px;
    background-color: #ffffff;
  }
}

.delivery.element__holder {
  margin-top: 24px;
  padding: 12px;
  border-radius: 8px;
  background-color: #f5f5f5;
  & .delivery__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    & .btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      & > a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 80px;
        min-height: 48px;
        border-radius: 8px;
        text-decoration: none;
        background-color: #ffffff;
        flex-direction: column;
        transition: 0.3s ease all;
        &.green:hover {
          background-color: #41d8001a;
        }
        &.yellow:hover {
          background-color: #ffbf001a;
        }
        &.red:hover {
          background-color: #de00001a;
        }
        margin-right: 8px;
        &:last-child {
          margin-right: 0px;
        }
        & > span {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 4px;
        }
        font-size: 12px;
        line-height: 14px;
        color: #1d1c19;
        font-weight: 500;
      }
    }
    & > p {
      margin: 0px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      & > span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 48px;
        max-width: 48px;
        min-height: 48px;
        max-height: 48px;
        background-color: #ffffff;
        border-radius: 6px;
        margin-right: 15px;
      }
      font-size: 14px;
      line-height: 18px;
      color: #121212;
      font-weight: bold;
    }
  }
  & .download__all {
    margin-top: 12px;
    & > a {
      background-color: #fff;
      color: #0e7d7d;
      border: 1px solid #e3e3e3;
      &:hover {
        background-color: #fff;
        border-color: #0e7d7d;
      }
      &:active {
        color: #ffffff;
        box-shadow: 0 0 0 0.2rem rgba(14, 125, 125, 0.5);
        background-color: #094f4f;
      }
    }
  }
}

.wrapper--controls .controls {
  min-width: 260px;
  max-width: 260px;
  margin-left: 24px;
  .btn {
    width: 100%;
    margin-bottom: 8px;
  }
  & .btn > a.request {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    color: #121212;
    background-color: #ffc700;
    transition: 0.3sease all;
  }
}
.wrapper--controls {
  min-width: 260px;
  max-width: 260px;
}

@media (max-width: 1200px) {
  .wrapper--controls {
    margin-left: 0px;
    min-width: 0px;
    max-width: 100%;
    width: 100%;
    margin-top: 24px;
  }

  .wrapper--controls .controls {
    margin-left: 0px;
    min-width: 0px;
    max-width: 100%;
    width: 100%;
    margin-top: 24px;
  }
}

.feedback-box {
  position: relative;
  max-height: 100%;
  overflow-y: auto;
  & .btns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 16px;
    margin-top: 40px;
  }
  & .area {
    width: 100%;
    margin-top: 24px;
    & > textarea {
      width: 100%;
      resize: none;
      height: 80px;
      border-radius: 6px;
      border: 1px solid #dadada;
      padding: 12px;
      font-size: 14px;
      line-height: 18px;
      color: #000;
      outline: none;
    }
  }
  & .group__checks {
    .checkbox__name {
      text-transform: capitalize;
    }
  }
  & .top {
    margin-bottom: 40px;
    & > h6 {
      margin-top: 0px;
      margin-bottom: 12px;
      font-size: 18px;
      line-height: 140%;
      font-weight: bold;
      color: #0e7d7d;
    }
    & > p {
      margin: 0px;
      font-size: 16px;
      line-height: 140%;
      color: #121212;
    }
  }
  & > .close__popup {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    top: 0px;
    right: 0px;
    transition: 0.3s ease all;
    &:hover {
      opacity: 0.7;
    }
  }
  width: 100%;
  max-width: 500px;
}
